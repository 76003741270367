import { makeAutoObservable } from 'mobx'

import { Store } from 'shared/types'

import QuestsStore from 'stores/QuestsStore'

import RacesHistoryStore from '../RacesHistoryStore'
import RootStore from '../RootStore'

class DashboardPageStore implements Store {
  rootStore: RootStore
  racesHistory: RacesHistoryStore
  quests: QuestsStore

  constructor (rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
    this.racesHistory = new RacesHistoryStore()
    this.quests = new QuestsStore()
  }

  reset (): void {
    this.racesHistory.reset()
    this.quests.reset()
  }
}

export default DashboardPageStore
