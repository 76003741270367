import React, { FC } from 'react'

import { Typography, Button } from '@mui/material'
import { useGetAccount, useGetActiveTransactionsStatus } from '@multiversx/sdk-dapp/hooks'

import { fetchNftsStaked, unStakeNfts } from '../../../hooks'

import StakingModal from './StakingModal'

const NftsStaked: FC<Props> = ({ scAddress }) => {
  const { address } = useGetAccount()
  const { success, fail } = useGetActiveTransactionsStatus()
  const [open, setOpen] = React.useState(false)
  const [nftsSelected, setNftsSelected] = React.useState<number[]>([])
  const [nfts, setNfts] = React.useState<any[]>([])

  const handleOpen = (): void => setOpen(true)
  const handleClose = (): void => setOpen(false)

  React.useEffect(() => {
    fetchNftsStaked(scAddress, address).then((nftsStaked) => setNfts(nftsStaked)).catch((err) => console.log(err))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, fail])

  return <>
    <Typography variant='h6' sx={{ marginBottom: '2px' } }>
        Your staked {nfts.length} NFTs.
        </Typography>
        <Button
            variant='contained'
                color='primary'
                sx={{
                  borderRadius: 20,
                  marginTop: '11px',
                  marginBottom: '12px',
                  padding: '16px 12px',
                  maxHeight: '20px'
                }}
                onClick={handleOpen}
                disabled={nfts.length === 0}
        >
        <Typography fontSize={'15px'} fontWeight={500}>
            View
        </Typography>
        </Button>
        <StakingModal
          open={open}
          onCloseClick={handleClose}
          title={'UnStake NFTs'}
          nfts={nfts}
          nftsSelected={nftsSelected}
          setNftsSelected={setNftsSelected}
          // eslint-disable-next-line
          handleFunc={() => unStakeNfts(scAddress, nftsSelected).then(() => setNftsSelected([])).catch((err) => console.log(err))}
          method={'unStake'}
        />
  </>
}

interface Props {
  scAddress: string
}

export default NftsStaked

export {
  NftsStaked
}
