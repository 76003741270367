import { EmitEvents, EnterRaceData, GenericResponse, GetAvailableResponse, HorseSft, RaceDoc, RacesData, ReceiveEvents, Token } from 'shared/types'

import BackendService from './BackendService'

export class RacesService extends BackendService {
  getRaces (
    success: (data: RacesData[]) => void,
    error: (error: any) => void
  ): void {
    this.emit(EmitEvents.GetRaces)
    this.on(ReceiveEvents.GetRaces, success, error)
  }

  getRace (
    id: string,
    address: string,
    success: (data: any) => void,
    error: (error: any) => void
  ): void {
    this.emit(EmitEvents.GetRace, { id, address })
    this.on(ReceiveEvents.GetRace, success, error)
  }

  enterRace (
    data: EnterRaceData,
    success: (data: GenericResponse) => void,
    error: (error: any) => void
  ): void {
    try {
      this.emitWithAck(EmitEvents.EnterRace, success, data)
    } catch (exception: any) {
      error(exception)
    }
  }

  getAvailable (
    race: RaceDoc,
    token: Token,
    address: string,
    success: (data: GetAvailableResponse) => void,
    error: (error: any) => void
  ): void {
    try {
      this.emitWithAck(EmitEvents.GetAvailable, success, race, token, address)
    } catch (exception: any) {
      error(exception)
    }
  }

  getHorse (
    horse: string,
    success: (data: HorseSft) => void,
    error: (error: any) => void
  ): void {
    try {
      this.emitWithAck(EmitEvents.Horse, success, horse)
    } catch (exception: any) {
      error(exception)
    }
  }

  getUserProfile (
    usernameUpperCase: string,
    success: (data: any) => void,
    error: (error: any) => void
  ): void {
    try {
      this.emitWithAck(EmitEvents.GetUserProfile, success, usernameUpperCase)
    } catch (exception: any) {
      error(exception)
    }
  }

  removeGetRacesListener (): void {
    this.disconnect(ReceiveEvents.GetRaces)
  }

  removeGetRaceListener (): void {
    this.disconnect(ReceiveEvents.GetRace)
  }
}
