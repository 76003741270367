import React, { FC } from 'react'

import { observer } from 'mobx-react-lite'
import { Helmet } from 'react-helmet'

import { DefaultLayout } from 'layouts'

import { Aside, TextHighlight } from 'components'

import BuildingsPageContent from './components/BuildingsPageContent'

const BuildingsPage: FC<Props> = observer(() => {
  return (
    <DefaultLayout
      data-testid={TEST_HOOKS.BUILDINGSPAGE}
      main={
        <>
          <Helmet title="Buildings" />
          <TextHighlight variant="h2">Buildings</TextHighlight>
          <BuildingsPageContent />
        </>
      }
      sidebar={<Aside />}
    />
  )
})

const TEST_HOOKS = {
  BUILDINGSPAGE: 'buildingsPage'
}

// FIXME: Either add props or remove the interface!
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {

}

export default BuildingsPage

export {
  BuildingsPage,
  TEST_HOOKS,
  type Props
}
