import { createTheme } from '@mui/material/styles'
import chroma from 'chroma-js'
import '@fontsource/source-sans-pro'

const sidebarWidth = 126

const colors = {
  main: '#2B7FFC',
  dark: '#14213D',
  darker: chroma('#14213D').darken(1).hex(),
  secondary: '#ECB70B',
  success: '#32AF97'
}

const linearGradient = (start: string, end: string, angle: number = 120): string => {
  return `linear-gradient(${angle}deg, ${start}, ${end})`
}

const boxShadow = (color: string = colors.main): string => `4px 4px 20px ${color}`
const textShadow = (color: string = colors.main): string => `4px 4px 20px ${color}`

let theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: colors.main,
      darker: chroma(colors.main).mix('#000', 0.8).hex()
    },
    secondary: {
      main: colors.secondary,
      darker: chroma(colors.secondary).darken(2).hex()
    },
    success: {
      main: colors.success,
      darker: chroma(colors.success).darken(2).hex()
    }
  },
  typography: {
    fontFamily: 'Source Sans Pro'
  }
})

theme = createTheme(theme, {
  typography: {
    h1: {
      fontSize: 32,
      fontWeight: 700
    },
    h2: {
      fontSize: 28,
      fontWeight: 700
    },
    h3: {
      fontSize: 24,
      fontWeight: 700
    },
    h4: {
      fontSize: 20,
      fontWeight: 700
    },
    h5: {
      fontSize: 18,
      fontWeight: 700
    },
    h6: {
      fontSize: 16,
      fontWeight: 700
    }
  },
  components: {
    MuiLink: {
      defaultProps: {
        underline: 'none'
      },
      styleOverrides: {
        root: {
          fontWeight: 500,
          textShadow: textShadow(theme.palette.primary.dark),
          transition: 'color .2s, boxShadow .2s',
          '&:hover': {
            color: theme.palette.grey['100'],
            textShadow: textShadow(theme.palette.grey['100'])
          }
        }
      }
    },
    MuiStack: {
      defaultProps: {
        spacing: 4
      }
    },
    MuiGrid: {
      defaultProps: {
        spacing: 4
      }
    },
    MuiGrid2: {
      defaultProps: {
        spacing: 4
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderRadius: 16
        }
      }
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true
      },
      styleOverrides: {
        tooltip: {
          borderRadius: 8,
          padding: '8px 12px',
          background: colors.darker,
          fontSize: 14
        },
        arrow: {
          color: colors.darker
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: 12,
          '& .MuiTouchRipple-root .MuiTouchRipple-child': {
            borderRadius: 12
          }
        }
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          borderRadius: 10
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 700,
          borderRadius: 10,
          boxShadow: boxShadow()
        }
      }
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0
      },
      styleOverrides: {
        root: {
          background: linearGradient(
            'rgba(0, 0, 0, 0.3)',
            'rgba(0, 0, 0, 0.5)',
            127
          ),
          borderRadius: 20
        }
      }
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          display: 'flex',
          justifyContent: 'center',
          padding: 20
        },
        content: {
          textAlign: 'center'
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '40px 20px'
        }
      }
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: 20,
          justifyContent: 'center'
        }
      }
    }
  }
})

export {
  theme,
  colors,
  linearGradient,
  boxShadow,
  textShadow,
  sidebarWidth
}
