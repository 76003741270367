import { useContext } from 'react'

import { RootContext } from './context'
import RootStore from './RootStore'

function useStores (): RootStore {
  return useContext(RootContext)
}

export {
  useStores
}
