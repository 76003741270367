import React, { FC, useEffect } from 'react'

import { Box, Typography } from '@mui/material'
import { type SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx'
import { observer } from 'mobx-react-lite'

import { theme } from 'shared/theme'
import { getNftUrl, isFreeHorse } from 'shared/utils'

import { useStores } from 'stores/hooks'

const NftCard: FC<Props> = observer(({ horse, sx = {} }) => {
  const { racesStore } = useStores()
  const horseName = horse === undefined ? '' : horse

  useEffect(() => {
    racesStore.getHorse(horseName)
  }, [horseName, racesStore])

  const horseResource = racesStore.horses.get(horseName)?.data

  return (
    <Box
      sx={(theme) => ({
        borderRadius: '8px',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'center',
        ...sx
      })}
      data-testid={TEST_HOOKS.NFTCARD}
    >
      <Box
        mt={isFreeHorse(horse) ? 2 : 0}
        sx={{
          display: 'flex',
          justifyContent: 'center'
        }}>
        <img
          src={isFreeHorse(horse) ? horseResource?.media : getNftUrl(horseName, horseResource?.collectionId)}
          width={'109'}
          alt={horse}
        />
      </Box>
      <Typography
        mt={isFreeHorse(horse) ? 1 : 0}
        align='center'>
        {horse}
      </Typography>
    </Box>
  )
})

const TEST_HOOKS = {
  NFTCARD: 'nftCard'
}

interface Props {
  horse: string | undefined
  sx?: SystemStyleObject<typeof theme>
}

export default NftCard

export {
  NftCard,
  TEST_HOOKS,
  type Props
}
