import { makeAutoObservable } from 'mobx'
import { TournamentsService } from 'services'
import { BuildingsService } from 'services/BuildingsService'

import BuildingsStore from 'stores/BuildingsStore/BuildingsStore'
import RacesStore from 'stores/RacesStore/RacesStore'
import AdminStore from 'stores/TournamentsStore/AdminStore'
import NftsStore from 'stores/TournamentsStore/NftsStore'
import TournamentsStore from 'stores/TournamentsStore/TournamentsStore'

import { AccountService } from '../../services/AccountService'
import { RacesService } from '../../services/RacesService'
import AccountStore from '../AccountStore/AccountStore'
import AsideStore from '../AsideStore'
import DashboardPageStore from '../DashboardPageStore'
import TournamentsDetailsStore from '../TournamentsStore/TournamentsDetailsStore'

class RootStore {
  aside: AsideStore
  dashboard: DashboardPageStore
  tournamentsStore: TournamentsStore
  tournamentsDetailsStore: TournamentsDetailsStore
  adminStore: AdminStore
  nftsStore: NftsStore
  accountStore: AccountStore
  racesStore: RacesStore
  buildingsStore: BuildingsStore

  constructor () {
    const tournamentsService = new TournamentsService()
    const accountService = new AccountService()
    const racesService = new RacesService()
    const buildingsService = new BuildingsService()
    makeAutoObservable(this)
    this.aside = new AsideStore(this)
    this.dashboard = new DashboardPageStore(this)
    this.tournamentsStore = new TournamentsStore(tournamentsService)
    this.tournamentsDetailsStore = new TournamentsDetailsStore(tournamentsService)
    this.nftsStore = new NftsStore(tournamentsService)
    this.adminStore = new AdminStore(tournamentsService)
    this.accountStore = new AccountStore(accountService)
    this.racesStore = new RacesStore(racesService)
    this.buildingsStore = new BuildingsStore(buildingsService)
  }
}

export default RootStore
