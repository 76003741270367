import React, { FC } from 'react'

import { Button } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'

import { Tournament } from 'shared/types'

import JoinModal from './JoinModal'

const JoinOrResultsButton: FC<Props> = observer(({ tournament }) => {
  const navigate = useNavigate()

  const [open, setOpen] = React.useState(false)

  const handleOpen = (): void => setOpen(true)
  const handleClose = (): void => setOpen(false)

  const handleOnClick = (): void => {
    if (tournament.stages.length === 0) {
      handleOpen()
      return
    }

    navigate(`/app/view/tournament/${encodeURIComponent(tournament._id)}`)
  }

  return (
    <>
      <Button
        className="joinBtn"
        variant="contained"
        color="primary"
        onClick={handleOnClick}
        sx={{
          fontSize: 20,
          fontWeight: 700,
          borderRadius: 20,
          padding: '4px 23px',
          maxHeight: '48px'
        }}>
        {tournament.stages.length === 0 ? 'JOIN' : 'SEE RESULTS'}
      </Button>
      <JoinModal
        tournament={tournament}
        open={open}
        onCloseClick={handleClose} />
    </>
  )
})

const TEST_HOOKS = {
  JOINORRESULTSBUTTON: 'joinOrResultsButton'
}

interface Props {
  tournament: Tournament
}

export default JoinOrResultsButton

export {
  JoinOrResultsButton,
  TEST_HOOKS,
  type Props
}
