import { makeAutoObservable } from 'mobx'
import { TournamentsService } from 'services'

import { Resource } from 'shared/resource'
import { GenericResponse, ResponseStatus, Store, Tournament } from 'shared/types'

class TournamentsStore implements Store {
  tournamentResource?: Resource<Tournament | undefined>
  tournamentsHistoryResource: Resource<Tournament[]> = Resource.success([])
  checkTournamentResource?: Resource<GenericResponse>
  enterTournamentResource?: Resource<string>

  tournamentsService: TournamentsService

  constructor (tournamentsService: TournamentsService) {
    this.tournamentsService = tournamentsService
    makeAutoObservable(this)
  }

  getCurrentTournament (): void {
    this.tournamentResource = Resource.loading()
    this.tournamentsService.getTournaments((data) => {
      this.tournamentResource = Resource.success(data.length > 0 ? data[0] : undefined)
    }, (error) => {
      this.tournamentResource = Resource.error(error)
    })
  }

  getTournamentsHistory (): void {
    this.tournamentsHistoryResource = Resource.loading()
    this.tournamentsService.getTournamentsHistory((data) => {
      this.tournamentsHistoryResource = Resource.success(data)
    }, (error) => {
      this.tournamentsHistoryResource = Resource.error(error)
    })
  }

  getTournamentCheck (
    tournamentId: string,
    address: string
  ): void {
    this.checkTournamentResource = Resource.loading()
    this.tournamentsService.checkTournament(tournamentId, address,
      (data) => {
        this.checkTournamentResource = Resource.success(data)
      }, (error) => {
        this.checkTournamentResource = Resource.error(error)
      })
  }

  enterTournament (
    address: string,
    horse: string,
    tournamentId: string
  ): void {
    this.enterTournamentResource = Resource.loading()
    this.tournamentsService.enterTournament(address, horse, tournamentId,
      (data) => {
        if (data.status === ResponseStatus.SUCCESS) {
          this.enterTournamentResource = Resource.success(horse)
        } else {
          this.enterTournamentResource = Resource.error(data)
        }
      }, (error) => {
        this.enterTournamentResource = Resource.error(error)
      })
  }

  reset (): void {
  }

  removeTournamentsHistoryListeners (): void {
    this.tournamentsService.removeTournamentsHistoryListener()
  }

  removeTournamentsListeners (): void {
    this.tournamentsService.removeTournamentsListener()
  }

  resetCheckTournamentResource (): void {
    this.checkTournamentResource = undefined
  }

  resetEnterTournamentResource (): void {
    this.enterTournamentResource = undefined
  }
}

export default TournamentsStore
