import { sendTransactions } from '@multiversx/sdk-dapp/services'
import { refreshAccount } from '@multiversx/sdk-dapp/utils'

export const sendStakeTransaction = async (data: string, gasLimit: number, receiver: string): Promise<void> => {
  const transaction = {
    value: 0,
    gasLimit,
    data,
    receiver
  }
  await refreshAccount()

  await sendTransactions({
    transactions: transaction,
    transactionsDisplayInfo: {
      processingMessage: 'Processing transaction',
      errorMessage: 'An error has occured during',
      successMessage: 'Transaction successful'
    },
    redirectAfterSign: false
  })
}
