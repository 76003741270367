import React, { FC, useMemo } from 'react'

import { Box } from '@mui/material'

import './raceRarityCell.css'

const RaceRarityCell: FC<Props> = ({ raceRarity }) => {
  const createArrayWithDefault = (input: string[]): Array<string | undefined> => {
    const defaultArray = Array(4 - input.length).fill(undefined)
    return [...input, ...defaultArray]
  }
  const inputArray = raceRarity
  const outputArray = useMemo(() => createArrayWithDefault(inputArray), [inputArray])

  const circleColor = (rarity: string | undefined): string => {
    if (rarity === 'COMMON') {
      return 'grey'
    }
    if (rarity === 'COMMON GOLD') {
      return 'gold'
    }
    if (rarity === 'RARE') {
      return '#1BA0FA'
    }
    if (rarity === 'ULTRA_RARE') {
      return 'darkblue'
    }
    if (rarity === 'EPIC') {
      return 'purple'
    }
    if (rarity === 'LEGENDARY') {
      return '#FF5733'
    }
    return '#FFFFFF30'
  }

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', maxWidth: '26px', marginTop: 1 }}>
      {outputArray.map((rarity, index) => (
        <Box key={index} sx={{ width: '50%', height: '13px' }}>
          <svg width="7px" height="7px" >
            <circle cx="3.5" cy="3.5" r="3.5" fill={`${circleColor(rarity)}`} />
          </svg>
        </Box>
      ))}
    </Box>
  )
}

const TEST_HOOKS = {
  RACERARITYCELL: 'raceRarityCell'
}

interface Props {
  raceRarity: string[]
}

export default RaceRarityCell

export {
  RaceRarityCell,
  TEST_HOOKS,
  type Props
}
