import React, { FC } from 'react'

import { Typography, Button } from '@mui/material'
import { useGetAccount } from '@multiversx/sdk-dapp/hooks'

import { stakeNfts } from '../../../hooks'

import StakingModal from './StakingModal'

const StakeNfts: FC<Props> = ({ scAddress, nfts }) => {
  const { address } = useGetAccount()
  const [open, setOpen] = React.useState(false)
  const [nftsSelected, setNftsSelected] = React.useState<number[]>([])
  const handleOpen = (): void => setOpen(true)
  const handleClose = (): void => setOpen(false)

  return <>
    <Typography variant='h6' sx={{ marginBottom: '2px' }}>
            You have {nfts.length} NFTs available for staking.
        </Typography>
        <Button
                variant='contained'
                color='primary'
                sx={{
                  borderRadius: 20,
                  marginTop: '11px',
                  padding: '16px 12px',
                  maxHeight: '20px'
                }}
                disabled={nfts.length === 0}
                onClick={handleOpen}
        >
        <Typography fontSize={'15px'} fontWeight={500}>
          Stake NFTS
        </Typography>
        </Button>
        <StakingModal
          open={open}
          onCloseClick={handleClose}
          title={'Stake NFTs'}
          nfts={nfts}
          nftsSelected={nftsSelected}
          setNftsSelected={setNftsSelected}
          // eslint-disable-next-line
          handleFunc={() => stakeNfts(scAddress, nftsSelected, address).then(() => setNftsSelected([])).catch((err) => console.log(err))}
          method={'stake'}
        />
    </>
}

interface Props {
  scAddress: string
  nfts: any[]
}

export default StakeNfts

export {
  StakeNfts
}
