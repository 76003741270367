import React, { FC } from 'react'

import { Card, CardContent, CardHeader, Grid } from '@mui/material'

import { RaceDoc, RaceObject, RacesBulkType, RacesData } from 'shared/types'

import { TextHighlight } from 'components'

import PayRaceButton from '../../pages/RacesPage/components/PayRaceButton'

import RaceRarityCell from './RaceRarityCell'

import './raceRarityCell.css'

const RaceItem: FC<RaceItemProps> = ({ raceDoc, type }) => {
  return (
    <Grid
      container
      spacing={2}
      sx={{
        textAlign: 'center',
        fontWeight: 500,
        marginTop: '4px'
      }}
    >
      {type === 'custom' &&
        <Grid item xs={2} className='no-padding'
          sx={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}>
          <RaceRarityCell raceRarity={raceDoc.rarity} />
        </Grid>
      }
      <Grid item xs={type === 'custom' ? 3 : 4}>
        {raceDoc.player.length}/8
      </Grid>
      <Grid item xs={type === 'custom' ? 3 : 4}>
        {raceDoc.prizePool} {raceDoc.with}
      </Grid>
      <Grid item xs={4}>
        <PayRaceButton raceDoc={raceDoc} type={type} />
      </Grid>
    </Grid>
  )
}

interface RaceItemProps {
  raceDoc: RaceDoc
  type: string
}

const RacesCard: FC<Props> = ({ racesBulk, bulkNumber }) => {
  const raceTypeTitle = (racesBulk: RacesData): string => {
    if (racesBulk.type === 'free') {
      return RacesBulkType.STAMINA
    }
    if (racesBulk.type === 'estar') {
      return RacesBulkType.ESTAR
    } if (racesBulk.type === 'egld') {
      return RacesBulkType.EGLD
    }
    return RacesBulkType.CUSTOM
  }

  return (
    <Card
      data-testid={TEST_HOOKS.RACESCARD}
      sx={{
        position: 'relative',
        background: '#FFFFFF14',
        minHeight: '500px'
      }}
    >
      <CardHeader
        title={`${raceTypeTitle(racesBulk)} RUNNING`}
        titleTypographyProps={{ component: TextHighlight, textTransform: 'uppercase' }}
        sx={{ position: 'relative', zIndex: 1, paddingTop: 4 }}
      />
      <CardContent sx={{ position: 'relative', zIndex: 1, paddingTop: 2 }}>
        <Grid
          container
          spacing={2}
          sx={{
            fontSize: 12,
            fontWeight: 700,
            textAlign: 'center',
            textTransform: 'uppercase',
            marginBottom: 2
          }}
        >
          {racesBulk.type === 'custom' &&
            <Grid item xs={2}>
            </Grid>}
          <Grid item xs={racesBulk.type === 'custom' ? 3 : 4}>
            Players
          </Grid>
          <Grid item xs={racesBulk.type === 'custom' ? 3 : 4}>
            Prize Pool
          </Grid>
          <Grid item xs={4}>
            Entry Fee
          </Grid>
        </Grid>
        {racesBulk.races.map((race: RaceObject, index) => (
          <RaceItem
            key={index}
            raceDoc={race._doc}
            type={racesBulk.type}
          />
        ))}
      </CardContent>
    </Card>
  )
}

const TEST_HOOKS = {
  RACESCARD: 'racesCard'
}

interface Props {
  racesBulk: RacesData
  bulkNumber: number
}

export default RacesCard

export {
  RacesCard,
  TEST_HOOKS,
  type Props
}
