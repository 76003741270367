import axios from 'axios'

import { BACKEND_API } from 'config'

export const fetchRewards = async (scAddress: string, address: string): Promise<number> => {
  try {
    const { data } = await axios.get(`${BACKEND_API}/stake/${scAddress}/users/${address}/rewards`)

    return Number(data.rewards.toFixed(2))
  } catch (error) {
    console.error(error)
    return 0
  }
}
