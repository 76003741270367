export enum Status {
  LOADING,
  SUCCESS,
  ERROR
}

export class Resource<T> {
  status: Status
  data?: T
  error?: any

  constructor (status: Status, data?: T, error?: any) {
    this.status = status
    this.data = data
    this.error = error
  }

  static success<T>(data: T): Resource<T> {
    return new Resource(Status.SUCCESS, data)
  }

  static error (error: any): Resource<any> {
    return new Resource(Status.ERROR, null, error)
  }

  static loading<T> (): Resource<T> {
    return new Resource(Status.LOADING)
  }
}
