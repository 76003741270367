import { Address } from '@multiversx/sdk-core'

export const isValidAddress = (address: string): boolean => {
  try {
    // eslint-disable-next-line no-new
    new Address(address)
    return true
  } catch {
    return false
  }
}
