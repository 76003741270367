import { EGLD, ESTAR, STAM, XLH } from 'shared/icons/web3'
import { SvgIconComponent } from 'shared/types'

const CurrencyIcons = new Map<string, SvgIconComponent>([
  ['estar', ESTAR],
  ['egld', EGLD],
  ['stamina', STAM],
  ['xlh', XLH],
  ['free', STAM],
  ['custom', STAM]
])

const ADDRESS_COOKIE_NAME = 'elrondAddress'

export {
  CurrencyIcons,
  ADDRESS_COOKIE_NAME
}
