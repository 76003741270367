import { makeAutoObservable } from 'mobx'

import { Resource } from 'shared/resource'
import { EnterRaceData, GenericResponse, GetAvailableResponse, GetRaceData, GetUserProfileResponse, HorseSft, RaceDoc, RacesData, Store, Token } from 'shared/types'

import { RacesService } from '../../services/RacesService'
class RacesStore implements Store {
  racesResource?: Resource<RacesData[]>
  races: Map<string, Resource<GetRaceData>> = new Map()
  enterRaceResource?: Resource<GenericResponse>
  getAvailableResource?: Resource<GetAvailableResponse>
  horses: Map<string, Resource<HorseSft>> = new Map()
  userProfileResource?: Resource<GetUserProfileResponse>

  racesService: RacesService

  constructor (racesService: RacesService) {
    this.racesService = racesService
    makeAutoObservable(this)
  }

  getRaces (): void {
    this.racesResource = Resource.loading()
    this.racesService.getRaces((data) => {
      this.racesResource = Resource.success(data)
    }, (error) => {
      this.racesResource = Resource.error(error)
    })
  }

  getRace (
    id: string,
    address: string
  ): void {
    this.races.set(id, Resource.loading())
    this.racesService.getRace(id, address, (data) => {
      this.races.set(id, Resource.success(data))
    }, (error) => {
      this.races.set(id, Resource.error(error))
    })
  }

  enterRace (
    data: EnterRaceData
  ): void {
    this.enterRaceResource = Resource.loading()
    this.racesService.enterRace(data,
      (data: GenericResponse) => {
        this.enterRaceResource = Resource.success(data)
      }, (error) => {
        this.enterRaceResource = Resource.error(error)
      })
  }

  getAvailable (
    race: RaceDoc,
    token: Token,
    address: string
  ): void {
    this.getAvailableResource = Resource.loading()
    this.racesService.getAvailable(race, token, address,
      (data: GetAvailableResponse) => {
        this.getAvailableResource = Resource.success(data)
      }, (error) => {
        this.getAvailableResource = Resource.error(error)
      })
  }

  getHorse (
    horse: string
  ): any {
    this.horses.set(horse, Resource.loading())
    this.racesService.getHorse(horse,
      (data: any) => {
        this.horses.set(horse, Resource.success(data))
      }, (error) => {
        this.horses.set(horse, Resource.error(error))
      })
  }

  getUserProfile (
    usernameUpperCase: string
  ): void {
    this.userProfileResource = Resource.loading()
    this.racesService.getUserProfile(usernameUpperCase,
      (data: GetUserProfileResponse) => {
        this.userProfileResource = Resource.success(data)
      }, (error) => {
        this.userProfileResource = Resource.error(error)
      })
  }

  reset (): void {
  }

  removeGetRacesResource (): void {
    this.racesService.removeGetRacesListener()
  }

  removeGetRaceResource (): void {
    this.racesService.removeGetRaceListener()
  }

  resetEnterRaceResource (): void {
    this.enterRaceResource = undefined
  }

  resetGetAvailableResource (): void {
    this.getAvailableResource = undefined
  }

  resetGetUserProfileResource (): void {
    this.userProfileResource = undefined
  }
}

export default RacesStore
