import React, { FC, useEffect } from 'react'

import { Card, CardActionArea, CardContent, Grid, Typography, useMediaQuery } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'

import grid from 'shared/images/grid.png'
import { Tournament } from 'shared/types'

import { useStores } from 'stores/hooks'

import { NftCard } from 'components'

const TournamentCard: FC<Props> = observer(({ tournament }) => {
  const navigate = useNavigate()

  const isDesktop = useMediaQuery('(min-width: 900px)')

  const handleClickCard = (): void => {
    navigate(`/app/view/tournament/${encodeURIComponent(tournament._id)}`)
  }
  const { tournamentsDetailsStore } = useStores()

  const tournamentResource = tournamentsDetailsStore.tournaments.get(tournament._id)?.data

  useEffect(() => {
    tournamentsDetailsStore.getTournament(tournament._id)

    return () => {
      tournamentsDetailsStore.reset()
    }
  }, [tournament._id, tournamentsDetailsStore])

  return (
    <Card onClick={handleClickCard} sx={{ background: '#FFFFFF14', minHeight: '400px' }}>
      <CardActionArea>
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundImage: `url(${grid})`,
            backgroundSize: '100%',
            backgroundRepeat: 'repeat',
            height: '160px',
            margin: '16px 16px 0 16px',
            borderRadius: '16px'
          }}
        >
          <Typography variant="h5" component="span" color="secondary">
            {tournamentResource?.prizePool} {tournamentResource?.prizeInToken}
          </Typography>
          <Typography
            component="span"
            sx={{
              textTransform: 'uppercase',
              fontSize: '14px',
              fontWeight: 700,
              letterSpacing: '1px'
            }}
          >
            Tournament #{tournament._id}
          </Typography>
          <Typography variant="body2" component="span" sx={{ fontWeight: 500 }}>
            {tournament.player}/{tournament.maxPlayers}
          </Typography>
        </CardContent>
        <CardContent sx={{ paddingTop: 0 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <NftCard horse={tournamentResource?.winners?.[0]?.horse} />
            </Grid>
            <Grid item xs={12} md={5} sx={{ textAlign: 'right' }}>
              <Typography sx={{ fontSize: '12px', textTransform: 'uppercase', fontWeight: 500 }}>
                {tournament.description}
              </Typography>
              <Typography
                mt={2}
                sx={(theme) => ({
                  color: theme.palette.success.main,
                  fontSize: '14px',
                  textTransform: 'uppercase',
                  fontWeight: 700,
                  textAlign: isDesktop ? 'end' : 'center'
                })}
              >
                {tournament.prizeInToken}
              </Typography>
              <Typography
                mt={2}
                sx={(theme) => ({
                  fontSize: '12px',
                  fontWeight: 500,
                  textTransform: 'uppercase',
                  color: theme.palette.grey['300'],
                  textAlign: isDesktop ? 'end' : 'center'
                })}
              >
                Score
              </Typography>
              <Typography sx={{
                fontSize: '14px',
                fontWeight: 700,
                textTransform: 'uppercase',
                textAlign: isDesktop ? 'end' : 'center'
              }}>
                {tournamentResource?.winners?.[0]?.score?.toFixed(2)}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  )
})

const TEST_HOOKS = {
  TOURNAMENTCARD: 'tournamentCard'
}

// FIXME: Either add props or remove the interface!
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {
  tournament: Tournament
}

export default TournamentCard

export {
  TournamentCard,
  TEST_HOOKS,
  type Props
}
