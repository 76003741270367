import React, { FC } from 'react'

import { observer } from 'mobx-react-lite'
import { Helmet } from 'react-helmet'

import { DefaultLayout } from 'layouts'

import { Aside, TextHighlight } from 'components'

import StakingCardsGrid from './components/StakingCardsGrid'

const StakingPage: FC = observer(() => {
  return (
     <DefaultLayout
     main={
       <>
         <Helmet title="Staking" />
         <TextHighlight variant="h2">Staking</TextHighlight>
         <StakingCardsGrid />
       </>
     }
     sidebar={<Aside />}
   />
  )
})

export default StakingPage

export {
  StakingPage
}
