import React, { FC } from 'react'

import { styled, useMediaQuery, useTheme } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

import { Equistar, EquistarSign } from 'shared/icons'

const EquistarLogo: FC<Props> = ({ href = '/' }) => {
  const theme = useTheme()
  const mdUp = useMediaQuery(theme.breakpoints.up('md'))

  const Logo = mdUp ? Equistar : EquistarSign

  const Link = styled(RouterLink)(({ theme }) => ({
    textDecoration: 'none',
    color: theme.palette.grey['50'],
    transition: 'color .2s',
    '&:hover': {
      color: theme.palette.secondary.main
    }
  }))

  return (
    <Link to={href} data-testid={TEST_HOOKS.EQUISTARLOGO}>
      <Logo height={42} width="auto" />
    </Link>
  )
}

const TEST_HOOKS = {
  EQUISTARLOGO: 'equistarLogo'
}

interface Props {
  href?: string
}

export default EquistarLogo

export {
  EquistarLogo,
  TEST_HOOKS,
  type Props
}
