import React, { PropsWithChildren } from 'react'

import { useGetIsLoggedIn } from '@multiversx/sdk-dapp/hooks'
import { Navigate } from 'react-router-dom'

const AuthRedirectWrapper = ({ children }: PropsWithChildren): JSX.Element => {
  const isLoggedIn = useGetIsLoggedIn()

  if (isLoggedIn) {
    return <Navigate to='/app' />
  }

  return <>{children}</>
}

export default AuthRedirectWrapper
