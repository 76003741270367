import DashboardSlider, { Props, SliderItemProps, TEST_HOOKS } from './DashboardSlider'

export default DashboardSlider

export {
  DashboardSlider,
  TEST_HOOKS,
  type Props,
  type SliderItemProps
}
