import React, { FC } from 'react'

import { Button, Grid, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'

import { TournamentStage } from '../../TournamentsDetailsPage'

const StageButtons: FC<Props> = observer(({ onFirstButtonClick, firstButtonStage, onSecondButtonClick, secondButtonStage }) => {
  return (
    <>
      <Grid item xs={12} md={10} mt={6} sx={{ display: 'flex', flexDirection: 'row' }}>
        <Button
          className='tournaments-gray-btn'
          onClick={() => { onFirstButtonClick() }}
          size="medium"
          variant="contained"
          sx={{
            mr: 8,
            background: '#FFFFFF14',
            boxShadow: 'none',
            backgroundColor: '#FFFFFF14',
            '&:hover': {
              backgroundColor: '#FFFFFF30'
            }
          }}>
          <Typography fontSize={'20px'} fontWeight={600}>
            VIEW {firstButtonStage}
          </Typography>
        </Button>
        <Button
          className='tournaments-gray-btn'
          onClick={() => { onSecondButtonClick() }}
          size="medium"
          variant="contained"
          sx={{
            background: '#FFFFFF14',
            boxShadow: 'none',
            backgroundColor: '#FFFFFF14',
            '&:hover': {
              backgroundColor: '#FFFFFF30'
            }
          }}
        >
          <Typography fontSize={'20px'} fontWeight={600}>
            VIEW {secondButtonStage}
          </Typography>
        </Button>
      </Grid>
    </>
  )
})

const TEST_HOOKS = {
  STAGEBUTTONS: 'stageButtons'
}

interface Props {
  onFirstButtonClick: () => void
  firstButtonStage: TournamentStage
  onSecondButtonClick: () => void
  secondButtonStage: TournamentStage
}

export default StageButtons

export {
  StageButtons,
  TEST_HOOKS,
  type Props
}
