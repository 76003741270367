import React, { FC, useEffect, useMemo, useState } from 'react'

import { Box, Button, ImageList, Typography, useMediaQuery } from '@mui/material'
import { useGetAccount } from '@multiversx/sdk-dapp/hooks'
import { Loader } from '@multiversx/sdk-dapp/UI'
import { observer } from 'mobx-react-lite'

import { Status } from 'shared/resource'
import { NftDetails, RaceDoc, ResponseStatus, Token } from 'shared/types'

import { useStores } from 'stores/hooks'

import GenericModal from 'components/GenericModal/GenericModal'

import AfterEnterRaceModal from '../AfterEnterRaceModal'

import DisplayHorseToRace from './DisplayHorseToRace'

import './selectRaceHorse.css'

const SelectRaceHorse: FC<Props> = observer(({ raceDoc, token }) => {
  const { nftsStore, racesStore } = useStores()
  const { address } = useGetAccount()

  const [openAfterEnter, setAfterOpenEnter] = useState(false)
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [title, setTitle] = useState('')
  const [nfts, setNfts] = useState<NftDetails[]>([])
  const [horseName, setHorseName] = useState('')

  const handleAfterEnterClose = (): void => setAfterOpenEnter(false)
  const handleClose = (): void => setOpen(false)

  useEffect(() => {
    nftsStore.getNfts(address)
    return () => {
      racesStore.resetEnterRaceResource()
    }
  }, [nftsStore, address, racesStore])

  const accountNfts = nftsStore.nftsResource?.data
  const enterRaceResource = racesStore.enterRaceResource

  const eligibleNfts: NftDetails[] = useMemo(() => {
    return accountNfts?.filter((nft: NftDetails) => {
      if (raceDoc.rarity.length === 0) {
        return (nft.stamina >= raceDoc.stamina && !nft.inRace)
      }
      return (nft.stamina >= raceDoc.stamina && !nft.inRace && raceDoc.rarity.includes(nft.rarity.toUpperCase()))
    }) ?? []
  }, [accountNfts, raceDoc.rarity, raceDoc.stamina])

  useEffect(() => {
    setNfts(eligibleNfts)
  }, [eligibleNfts])

  useEffect(() => {
    if (enterRaceResource?.data?.status === ResponseStatus.SUCCESS) {
      setAfterOpenEnter(true)
      setNfts((prev) => prev.filter((item) => item.name !== horseName))
      return
    }
    if (enterRaceResource?.data?.status === ResponseStatus.FAIL) {
      setMessage(enterRaceResource?.data?.message)
      setTitle(enterRaceResource?.data?.status)
      setOpen(true)
      racesStore.resetEnterRaceResource()
    }
  }, [enterRaceResource, horseName, racesStore])

  const sendHorseRace = (horse: string): void => {
    const data = {
      address,
      horse,
      feePaid: true,
      entryFee: raceDoc.entryFee,
      with: raceDoc.with,
      raceId: raceDoc._id
    }
    setHorseName(horse)
    racesStore.enterRace(data)
  }

  const isMobile = useMediaQuery('(max-width: 600px)')
  const isTablet = useMediaQuery('(max-width: 960px)')

  const cols = useMemo(() => {
    if (isMobile) {
      return 1
    }

    if (isTablet) {
      return 3
    }

    return 4
  }, [isMobile, isTablet])

  if (enterRaceResource?.status === Status.LOADING || nftsStore.nftsResource?.status === Status.LOADING) {
    return <Loader className='loader centered' noText={true} />
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <Button
          className='profile-gray-btn'
          size="medium"
          variant="contained"
          disableRipple
          disableFocusRipple
          sx={{
            background: '#FFFFFF14',
            boxShadow: 'none',
            '&:hover': {
              backgroundColor: '#FFFFFF14',
              cursor: 'default'
            },
            mt: 4
          }}
        >
          <Typography fontSize={'27px'} fontWeight={600} sx={{ px: '95px', py: '8px' }}>
            Horses
          </Typography>
        </Button>
      </Box>
      {
        nfts?.length === 0
          ? (<Typography fontSize={'20px'} fontWeight={550} align={'center'} sx={{ px: '95px', py: '8px', marginTop: '50px' }}>
            You do not have any eligible horse NFTs.
          </Typography>)
          : nfts !== undefined && (<ImageList cols={cols} gap={10}>
            {nfts.map((nft: NftDetails) => (
              <DisplayHorseToRace nft={nft} onNftClick={sendHorseRace} key={nft.name}/>
            )) ?? []}
          </ImageList>)
      }
      <AfterEnterRaceModal openAfterEnter={openAfterEnter} onCloseClickAfterEnter={handleAfterEnterClose} raceDoc={raceDoc} enterRaceResponse={enterRaceResource} />
      <GenericModal open={open} onCloseClick={handleClose} message={message} title={title}/>
    </>
  )
})

const TEST_HOOKS = {
  SELECTRACEHORSE: 'selectRaceHorse'
}

interface Props {
  raceDoc: RaceDoc
  token: Token
}

export default SelectRaceHorse

export {
  SelectRaceHorse,
  TEST_HOOKS
}
