import { makeAutoObservable } from 'mobx'
import { BuildingsService } from 'services/BuildingsService'

import { Resource } from 'shared/resource'
import { FarmResponseType, Store } from 'shared/types'

class BuildingsStore implements Store {
  farmResource?: Resource<FarmResponseType>

  buildingsService: BuildingsService

  constructor (buildingsService: BuildingsService) {
    this.buildingsService = buildingsService
    makeAutoObservable(this)
  }

  getFarm (
    address: string
  ): void {
    this.farmResource = Resource.loading()
    this.buildingsService.getFarm(address,
      (data: FarmResponseType) => {
        this.farmResource = Resource.success(data)
      }, (error) => {
        this.farmResource = Resource.error(error)
      })
  }

  unlockFarm (
    address: string
  ): void {
    this.buildingsService.unlockFarm(address,
      (data: FarmResponseType) => {
        this.farmResource = Resource.success(data)
      }, (error) => {
        this.farmResource = Resource.error(error)
      })
  }

  upgradeFarm (
    address: string
  ): void {
    this.buildingsService.upgradeFarm(address,
      (data: FarmResponseType) => {
        this.farmResource = Resource.success(data)
      }, (error) => {
        this.farmResource = Resource.error(error)
      })
  }

  reset (): void {
  }

  resetFarmResource (): void {
    this.farmResource = undefined
  }
}

export default BuildingsStore
