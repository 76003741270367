import React, { FC, useEffect } from 'react'

import { ImageListItem, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'

import { NftDetails } from 'shared/types'
import { getNftUrl, isFreeHorse } from 'shared/utils'

import { useStores } from 'stores/hooks'

const NftsDisplay: FC<Props> = observer(({ nftDetails, onNftClick }) => {
  const { racesStore } = useStores()

  const horseResource = racesStore.horses.get(nftDetails.name)

  useEffect(() => {
    racesStore.getHorse(nftDetails.name)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ImageListItem
      onClick={() => onNftClick(nftDetails.name)}
      sx={{
        background: '#FFFFFF14',
        borderRadius: '20px',
        paddingBottom: '22px'
      }}>
      <img
        src={isFreeHorse(nftDetails.name) ? horseResource?.data?.media : getNftUrl(nftDetails.name, horseResource?.data?.collectionId)}
        alt={nftDetails.name}
      />
      <Typography fontSize={'16px'} fontWeight={600} sx={{ textAlign: 'center' }}>
        {nftDetails.name}
      </Typography>
      <Typography fontSize={'12px'} fontWeight={400} sx={{ textAlign: 'center', marginTop: '10px' }}>
        Stamina: {nftDetails.stamina}
      </Typography>
    </ImageListItem>
  )
})

const TEST_HOOKS = {
  NFTSDISPLAY: 'nftsDisplay'
}

interface Props {
  nftDetails: NftDetails
  onNftClick: (horseName: string) => void
}

export default NftsDisplay

export {
  NftsDisplay,
  TEST_HOOKS,
  type Props
}
