import React, { FC, Suspense } from 'react'

import { Box, CssBaseline, ThemeProvider } from '@mui/material'
import {
  TransactionsToastList,
  SignTransactionsModals,
  NotificationModal
} from '@multiversx/sdk-dapp/UI'
import {
  AxiosInterceptorContext,
  DappProvider
} from '@multiversx/sdk-dapp/wrappers'
import chroma from 'chroma-js'
import Helmet from 'react-helmet'
import { RouterProvider } from 'react-router-dom'

import {
  API_TIMEOUT,
  ENVIRONMENT,
  WALLET_CONNECT_PROJECT_V2
} from 'config'

import { router } from 'config/routes'

import { colors, linearGradient, theme } from 'shared/theme'

import { LazyLoadingPage } from 'pages'

const App: FC = () => {
  return (
    <AxiosInterceptorContext.Provider>
      <AxiosInterceptorContext.Interceptor
        authenticatedDomanis={[]}
      >
        <DappProvider
          environment={ENVIRONMENT}
          dappConfig={{
            shouldUseWebViewProvider: true
          }}
          customNetworkConfig={{
            name: 'customConfig',
            apiTimeout: API_TIMEOUT,
            walletConnectV2ProjectId: WALLET_CONNECT_PROJECT_V2
          }}
        >
          <ThemeProvider theme={theme}>
            <TransactionsToastList />
            <NotificationModal />
            <SignTransactionsModals />
            <AxiosInterceptorContext.Listener />
            <Helmet titleTemplate="%s | Equistar" defaultTitle="Equistar" />
            <CssBaseline />
            <Box
              sx={{
                minHeight: '100vh',
                background: linearGradient(
                  `${chroma(colors.dark).mix(colors.main, 0.1).hex()}`,
                  `${chroma(colors.dark).darken(0.5).hex()} 50%`
                )
              }}
            >
              <Suspense fallback={<LazyLoadingPage />}>
                <RouterProvider router={router} />
              </Suspense>
            </Box>
          </ThemeProvider>
        </DappProvider>
      </AxiosInterceptorContext.Interceptor>
    </AxiosInterceptorContext.Provider>
  )
}

export default App
