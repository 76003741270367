import React, { FC } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import { Button, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'

import { Resource } from 'shared/resource'
import { GenericResponse, RaceDoc, ResponseStatus } from 'shared/types'

const AfterEnterRaceModal: FC<Props> = observer(({ openAfterEnter, onCloseClickAfterEnter, raceDoc, enterRaceResponse }) => {
  const navigate = useNavigate()

  const handleClickViewRace = (): void => {
    navigate(`/app/view/race/${encodeURIComponent(raceDoc._id)}`)
  }

  return (
    <div>
      <Dialog
        open={openAfterEnter}
        onClose={onCloseClickAfterEnter}
        scroll='paper'
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        disableScrollLock={true}
      >
        <DialogTitle sx={{ bgcolor: '#14213D', padding: '10px 15px' }} id="scroll-dialog-title">
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Typography fontWeight={600} sx={{ flexGrow: 1 }}>{enterRaceResponse?.data?.status}</Typography>
            <CloseIcon sx={{ cursor: 'pointer' }} onClick={onCloseClickAfterEnter} />
          </Box>
        </DialogTitle>
        <DialogContent sx={{ bgcolor: '#14213D' }} dividers={true}>
          <Box
            id="scroll-dialog-description"
            tabIndex={-1}
            width='100%'
          >
            {(enterRaceResponse?.data?.status === ResponseStatus.SUCCESS)
              ? (<Button onClick={handleClickViewRace} variant="contained">
                Go to view Race {raceDoc._id}
              </Button>)
              : (<Typography fontWeight={600} sx={{ flexGrow: 1 }}>{enterRaceResponse?.data?.message}</Typography>)}
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  )
})

const TEST_HOOKS = {
  AFTERENTERRACEMODAL: 'afterEnterRaceModal'
}

interface Props {
  openAfterEnter: boolean
  onCloseClickAfterEnter: () => void
  raceDoc: RaceDoc
  enterRaceResponse: Resource<GenericResponse> | undefined
}

export default AfterEnterRaceModal

export {
  AfterEnterRaceModal,
  TEST_HOOKS,
  type Props
}
