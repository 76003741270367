import React, { FC, useEffect } from 'react'

import { Box, Typography } from '@mui/material'
import { useGetAccount } from '@multiversx/sdk-dapp/hooks'
import { Loader } from '@multiversx/sdk-dapp/UI'
import { observer } from 'mobx-react-lite'

import { Status } from 'shared/resource'
import { ResponseStatus, Tournament } from 'shared/types'

import { useStores } from 'stores/hooks'

import EnterTournament from './EnterTournament'

const JoinModalContent: FC<Props> = observer(({ tournament }) => {
  const { tournamentsStore } = useStores()

  const { address } = useGetAccount()

  const checkTournamentResource = tournamentsStore.checkTournamentResource

  useEffect(() => {
    tournamentsStore.getTournamentCheck(tournament._id, address)
    return () => {
      tournamentsStore.resetCheckTournamentResource()
    }
  }, [address, tournament._id, tournamentsStore])

  if (checkTournamentResource?.status === Status.LOADING) {
    return (
      <Box sx={{ p: 4 }}>
        <Loader className='loader' noText={true} />
      </Box>
    )
  }

  if (checkTournamentResource?.data?.status === ResponseStatus.FAIL) {
    return (
      <>
        <Typography sx={{ textAlign: 'center', p: 4 }} id="modal-modal-title" variant="h6" component="h2">
          {checkTournamentResource?.data?.message}
        </Typography>
      </>
    )
  }

  return (
    <EnterTournament tournament={tournament} />
  )
})

const TEST_HOOKS = {
  JOINMODALCONTENT: 'joinModalContent'
}

interface Props {
  tournament: Tournament
}

export default JoinModalContent

export {
  JoinModalContent,
  TEST_HOOKS,
  type Props
}
