import React, { FC } from 'react'

import { Card, CardContent, Grid, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'

import { Tournament } from 'shared/types'

import JoinOrResultsButton from './JoinOrResultsButton'

const TournamentDetailsCard: FC<Props> = observer(({ tournament }) => {
  return (
    <Card
      data-testid={TEST_HOOKS.TOURNAMENTSDETAILSCARD}
      sx={{
        position: 'relative',
        background: '#FFFFFF14'
      }}
    >
      <CardContent sx={{ zIndex: 1, pt: 0 }}>
        <Grid
          container
          direction="column"
          sx={{
            marginBottom: 2,
            marginTop: 1
          }}
        >
          <Grid item xs={2}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              spacing={0} >
              <Grid
                item
                xs={12}
                sm={5.5}
                sx={{ mr: 0 }}>
                <Grid
                  item
                  direction="column"
                  alignItems="center"
                  sm={12}
                  sx={{ ml: 1, pr: 2 }}
                >
                  <Grid item xs={6}
                    sx={{
                      fontSize: 28,
                      fontWeight: 700,
                      color: '#FFC300',
                      textAlign: 'center'
                    }}>{tournament?.description}
                  </Grid>
                  <Grid item xs={6} sx={{ fontStyle: 'italic', fontSize: 16, textAlign: 'center' }}>ENDS on {tournament?.end}</Grid>
                </Grid>
              </Grid>
              <Grid item sm={1} sx={{
                display: 'flex',
                justifyContent: 'center'
              }}>
                <Grid
                  sx={{
                    width: '3px',
                    bgcolor: '#FFFFFF80',
                    height: '100%'
                  }}
                />
              </Grid>
              <Grid item
                xs={12}
                sm={5.5}
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', pl: 2 }}>
                <Grid
                  container
                  sx={{
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  <Grid item xs={12} sm={12} md={12} lg={6} sx={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <Typography fontSize={20} fontWeight={600}>
                      FEE
                    </Typography>
                    <Typography fontSize={16} fontWeight={400}>
                      {tournament.entryFee} {tournament.prizeInToken}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6} sx={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <Typography fontSize={20} fontWeight={600}>
                      TYPE
                    </Typography>
                    <Typography fontSize={16} fontWeight={400}>
                      {tournament.type}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6} sx={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <Typography fontSize={20} fontWeight={600}>
                      PLAYERS
                    </Typography>
                    <Typography fontSize={16} fontWeight={400}>
                      {tournament.player}/{tournament.maxPlayers}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6} sx={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <Typography fontSize={20} fontWeight={600}>
                      PRIZE POOL
                    </Typography>
                    <Typography fontSize={16} fontWeight={400}>
                      {tournament.prizePool} {tournament.prizeInToken}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}
            textAlign="center"
            sx={{
              marginTop: 6
            }}>
            <JoinOrResultsButton
              tournament={tournament}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
})

const TEST_HOOKS = {
  TOURNAMENTSDETAILSCARD: 'racesCard'
}

// FIXME: Either add props or remove the interface!
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {
  tournament: Tournament
}

export default TournamentDetailsCard

export {
  TournamentDetailsCard,
  TEST_HOOKS,
  type Props
}
