import React, { FC } from 'react'

import { AccountBoxOutlined } from '@mui/icons-material'
import {
  AppBar,
  Box,
  ButtonBase,
  IconButton,
  Stack,
  Toolbar,
  Tooltip
} from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

import { Estar } from 'shared/icons'

import { EquistarLogo } from 'components'

const EstarLogo: FC = () => {
  return (
    <Tooltip title="ESTAR GAMES" placement="right">
      <ButtonBase
        href="https://estar.games/"
        target="_blank"
        rel="noopener noreferrer"
        sx={{ display: 'block', padding: 0, borderRadius: '50%', marginLeft: '18px' }}
      >
        <Estar width={46} height={46} style={{ display: 'block' }} />
      </ButtonBase>
    </Tooltip>
  )
}

const TopBar: FC<Props> = () => {
  return (
    <AppBar
      position="relative"
      test-id={TEST_HOOKS.TOPBAR}
      sx={{ background: 'transparent', boxShadow: 'none', paddingTop: 1, paddingBottom: 1, zIndex: 2 }}
    >
      <Toolbar component={Box} sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <EstarLogo />
        <EquistarLogo href="/app" />
        <Stack direction="row" spacing={2}>
          {/* <IconButton>
            <Badge variant="dot" color="primary" overlap="circular">
              <NotificationsOutlined />
            </Badge>
          </IconButton>
          <IconButton>
            <PeopleOutlined />
          </IconButton> */}
          <IconButton
          component={RouterLink}
          to='/app/profile'>
            <AccountBoxOutlined />
          </IconButton>
        </Stack>
      </Toolbar>
    </AppBar>
  )
}

const TEST_HOOKS = {
  TOPBAR: 'topBar'
}

// FIXME: Either add props or remove the interface!
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {

}

export default TopBar

export {
  TopBar,
  TEST_HOOKS,
  type Props
}
