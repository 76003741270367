import React, { FC } from 'react'

import { Typography } from '@mui/material'

import ClaimRewards from './ClaimRewards'
import NftsStaked from './NftsStaked'
import StakeNfts from './StakeNfts'

const StakingCardFunc: FC<Props> = ({ scAddress, nfts }) => {
  return (
    <>
      <Typography variant="h1" sx={{ marginBottom: '12px' }}>
        STAKE
      </Typography>
      <ClaimRewards scAddress={scAddress} />
      <NftsStaked scAddress={scAddress} />
      <StakeNfts nfts={nfts} scAddress={scAddress} />
    </>
  )
}

interface Props {
  scAddress: string
  nfts: any[]
}

export default StakingCardFunc
export {
  StakingCardFunc
}
