import React, { FC, useEffect } from 'react'

import { useGetAccount } from '@multiversx/sdk-dapp/hooks'
import { observer } from 'mobx-react-lite'

import { useStores } from 'stores/hooks'

import { TextHighlight, LastRacesList } from 'components'

import ClaimFreeHorses from 'components/ClaimFreeHorses/ClaimFreeHorses'

const Aside: FC<Props> = observer(() => {
  const { address } = useGetAccount()
  const { aside } = useStores()

  useEffect(() => {
    void aside.initialize(address)
  }, [aside, address])

  return (
    <div>
      <div data-testid={TEST_HOOKS.ASIDE}>
        <TextHighlight variant="h4" component="h2">
          Last Races
        </TextHighlight>
        <LastRacesList races={aside.lastRaces.races.slice(0, 5)} />
      </div>
      <div>
        <ClaimFreeHorses address={address} />
      </div>
    </div>
  )
})

const TEST_HOOKS = {
  ASIDE: 'aside'
}

// FIXME: Either add props or remove the interface!
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {

}

export default Aside

export {
  Aside,
  TEST_HOOKS,
  type Props
}
