import i18n from 'i18next'
import ICU from 'i18next-icu'
import { initReactI18next } from 'react-i18next'

const config: typeof i18n = i18n
  .use(ICU)
  .use(initReactI18next)

export {
  config as i18nConfig
}
