import React, { FC, ReactNode, useEffect } from 'react'

import { useGetAccount } from '@multiversx/sdk-dapp/hooks'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router'

const AuthenticatedOnlyWrapper: FC<Props> = observer(({ children = null }) => {
  const { address } = useGetAccount()
  const isLoggedIn = Boolean(address)

  const navigate = useNavigate()

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/')
    }
  }, [navigate, isLoggedIn])

  if (!isLoggedIn) {
    return null
  }

  return (
    <div data-testid={TEST_HOOKS.AUTHENTICATEDONLYWRAPPER}>
      {children}
    </div>
  )
})

const TEST_HOOKS = {
  AUTHENTICATEDONLYWRAPPER: 'authenticatedOnlyWrapper'
}

interface Props {
  children?: ReactNode
}

export default AuthenticatedOnlyWrapper

export {
  AuthenticatedOnlyWrapper,
  TEST_HOOKS,
  type Props
}
