import axios from 'axios'

import { MULTIVERSX_API } from 'config'

export const fetchNfts = async (address: string): Promise<any[]> => {
  try {
    const { data } = await axios.get(`${MULTIVERSX_API}/accounts/${address}/nfts?collection=EQUISTAR-3f393f&size=3000`)
    return data
  } catch (err) {
    console.log(err)
    return []
  }
}
