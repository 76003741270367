import { EmitEvents, FarmResponseType } from 'shared/types'

import BackendService from './BackendService'

export class BuildingsService extends BackendService {
  getFarm (
    address: string,
    success: (data: FarmResponseType) => void,
    error: (error: any) => void
  ): void {
    try {
      this.emitWithAck(EmitEvents.GetFarm, success, address)
    } catch (exception: any) {
      error(exception)
    }
  }

  unlockFarm (
    address: string,
    success: (data: FarmResponseType) => void,
    error: (error: any) => void
  ): void {
    try {
      this.emitWithAck(EmitEvents.UnlockFarm, success, address)
    } catch (exception: any) {
      error(exception)
    }
  }

  upgradeFarm (
    address: string,
    success: (data: FarmResponseType) => void,
    error: (error: any) => void
  ): void {
    try {
      this.emitWithAck(EmitEvents.UpgradeFarm, success, address)
    } catch (exception: any) {
      error(exception)
    }
  }
}
