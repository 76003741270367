import React, { FC, useMemo } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import { Box, Dialog, DialogContent, DialogTitle, Typography, Grid, Button, ImageList, ImageListItem, useMediaQuery } from '@mui/material'
import { observer } from 'mobx-react-lite'

const StakingModal: FC<Props> = observer(({ open, onCloseClick, title, nfts, nftsSelected, setNftsSelected, handleFunc, method }) => {
  const isMobile = useMediaQuery('(max-width: 600px)')
  const isTablet = useMediaQuery('(max-width: 960px)')

  const cols = useMemo(() => {
    if (isMobile) {
      return 2
    }

    if (isTablet) {
      return 3
    }

    return 4
  }, [isMobile, isTablet])

  const handleChange = (nonce: number): void => {
    if (nftsSelected.includes(nonce)) {
      setNftsSelected(nftsSelected.filter((nft) => nft !== nonce))
    } else {
      setNftsSelected([...nftsSelected, nonce])
    }
  }

  return (
    <Dialog
      open={open}
      onClose={onCloseClick}
      maxWidth='xl'
      scroll='paper'
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      disableScrollLock={ true }
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '60%',
            height: '80%'
          }
        }
      }}
    >
      <DialogTitle sx={{ bgcolor: '#14213D', padding: '10px 15px' }} id="scroll-dialog-title">
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography fontWeight={600} sx={{ flexGrow: 1 }}>{title}</Typography>
          <CloseIcon sx={{ cursor: 'pointer' }} onClick={onCloseClick} />
        </Box>
      </DialogTitle>
      <DialogContent sx={{ bgcolor: '#14213D' }} dividers={true}>
        <Box
          id="scroll-dialog-description"
          tabIndex={-1}
          width='100%'
        >
          <Box>
            <Typography variant="h6" sx={{ marginBottom: '2px' }} align='center'>
              Select NFTs to {method}.
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={4}>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ borderRadius: 20, marginTop: '11px', padding: '16px 12px', maxHeight: '20px', display: 'flex', mx: 'auto' }}
                  onClick={() => setNftsSelected(nfts.map((nft) => nft.nonce))}
                >
                  <Typography fontSize={'15px'} fontWeight={500}>Select All</Typography>
                </Button>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ borderRadius: 20, marginTop: '11px', padding: '16px 12px', maxHeight: '20px', display: 'flex', mx: 'auto' }}
                  onClick={() => setNftsSelected([])}
                >
                  <Typography fontSize={'15px'} fontWeight={500}>Deselect</Typography>
                </Button>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={nftsSelected.length === 0}
                  sx={{ borderRadius: 20, marginTop: '11px', padding: '16px 12px', maxHeight: '20px', display: 'flex', mx: 'auto' }}
                  onClick={handleFunc}
                >
                  <Typography fontSize={'15px'} fontWeight={500}>{method}</Typography>
                </Button>
              </Grid>
            </Grid>
          </Box>
          <ImageList cols={cols} gap={10}>
            {nfts.map((nft) => (
              <ImageListItem
              key={nft.nonce}
              onClick={() => handleChange(nft.nonce)}
              sx={{
                ...(nftsSelected.includes(nft.nonce) && {
                  border: '2px solid #FFFFFF'
                }),
                background: '#FFFFFF14',
                borderRadius: '20px',
                paddingBottom: '22px'
              }}>
                <img
                  src={nft.url !== undefined ? nft.url : nft.media}
                  alt={nft.name}
                />
                <Typography fontSize={'16px'} fontWeight={600} sx={{ textAlign: 'center' }}>
                  {nft.name}
                </Typography>
              </ImageListItem>
            ))}
          </ImageList>
        </Box>
      </DialogContent>
    </Dialog>)
})

const TEST_HOOKS = {
  VIEWHORSESSTAKEDMODAL: 'ViewHorsesStakedModal'
}

interface Props {
  open: boolean
  onCloseClick: () => void
  title: string
  nfts: any[]
  nftsSelected: number[]
  setNftsSelected: (nftsSelected: number[]) => void
  handleFunc: () => void
  method: 'stake' | 'unStake'
}

export default StakingModal

export {
  StakingModal,
  TEST_HOOKS,
  type Props
}
