import React, { FC, useEffect } from 'react'

import { Box, ImageListItem, Typography } from '@mui/material'
import { ReactComponent as StaminaLogo } from 'assets/img/ic-stamina.svg'
import { observer } from 'mobx-react-lite'

import './selectRaceHorse.css'
import { useStores } from 'stores/hooks'

import { NftDetails } from '../../../../shared/types'
import { getNftUrl, isFreeHorse } from '../../../../shared/utils'

const DisplayHorseToRace: FC<Props> = observer(({ nft, onNftClick }) => {
  const { racesStore } = useStores()

  const horseResource = racesStore.horses.get(nft.name)

  useEffect(() => {
    if (isFreeHorse(nft.name)) {
      racesStore.getHorse(nft.name)
    };
  }, [nft.name, racesStore])

  return (
    <ImageListItem
    onClick={() => onNftClick(nft.name)}
    sx={{
      background: '#FFFFFF14',
      borderRadius: '20px',
      paddingBottom: '22px'
    }}>
      <img
         src={isFreeHorse(nft.name) ? horseResource?.data?.media : getNftUrl(nft.name, nft.collectionId)}
        alt={nft.name}
      />
      <Typography fontSize={'20px'} fontWeight={600} sx={{ textAlign: 'center' }}>
        {nft.name}
      </Typography>
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '10px'
      }}>
        <Typography fontSize={'16px'} fontWeight={400} sx={{ textAlign: 'center', marginRight: '4px' }}>
          {nft.stamina}
        </Typography>
        <StaminaLogo />
      </Box>
    </ImageListItem>
  )
})

const TEST_HOOKS = {
  DISPLAYHORSETORACE: 'displayHorseToRace'
}

interface Props {
  nft: NftDetails
  onNftClick: (horseName: string) => void
}

export default DisplayHorseToRace

export {
  DisplayHorseToRace,
  TEST_HOOKS,
  type Props
}
