import React, { FC, useEffect } from 'react'

import { Box, Button, Grid, Typography } from '@mui/material'
import { Loader } from '@multiversx/sdk-dapp/UI'
import { observer } from 'mobx-react-lite'

import { Status } from 'shared/resource'

import { useStores } from 'stores/hooks'

import BadgeImage from 'pages/ProfilePage/components/BadgeImage/BadgeImage'
import ClaimRewards from 'pages/ProfilePage/components/ClaimRewards/ClaimRewards'

import { TextHighlight } from 'components'

import profilePic from '../../../shared/images/profilePic.png'

import './userProfilePage.css'

const UserProfileContent: FC<Props> = observer(({ profileUsername }) => {
  const { racesStore } = useStores()

  const profileUsernameResource = racesStore.userProfileResource
  const account = profileUsernameResource?.data?.data

  useEffect(() => {
    racesStore.getUserProfile(profileUsername)

    return () => {
      racesStore.resetGetUserProfileResource()
    }
  }, [profileUsername, racesStore])

  if (racesStore.userProfileResource?.status === Status.LOADING) {
    return (
      <Loader className='loader' noText={true} />
    )
  }
  if (racesStore.userProfileResource?.data?.data.address === '') {
    return (
      <Box>
        <Typography fontSize={'20px'} fontWeight={600} sx={{ marginTop: '35px' }}>
          Account not found!
        </Typography>
      </Box>
    )
  }

  return (
    <>
      <TextHighlight variant="h2"
        sx={{ mt: 2, mb: 4 }}
      >
        {profileUsername}&apos;s Profile Page
      </TextHighlight>
      <Grid container
        direction="row"
        justifyContent="space-around"
        alignItems="center">
        <Grid item className='no-left-padding'>
          <Grid
            container
            className='no-left-padding'
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column'
            }}
          >
            <img src={profilePic} alt="Your SVG" width="172px" height="443px" />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Grid
            container
            sx={{
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <Button
              className='profile-gray-btn'
              size="medium"
              variant="contained"
              disableRipple
              sx={{
                background: '#FFFFFF14',
                boxShadow: 'none',
                '&:hover': {
                  backgroundColor: '#FFFFFF14',
                  cursor: 'default'
                },
                mt: 4
              }}
            >
              <Typography fontSize={'25px'} fontWeight={600}>
                STATISTICS
              </Typography>
            </Button>
          </Grid>
          <Grid
            container
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 1
            }}
          >
            <Grid item className='no-left-padding' xs={6} sx={{ display: 'flex', flexDirection: 'column' }}
            >
              <Typography fontSize={18} fontWeight={600} align={'left'} sx={{ color: '#2B7FFC' }}>
                LEVEL
              </Typography>
              <Typography fontSize={18} fontWeight={400} align={'left'}>
                {account?.statistics.level}
              </Typography>
              <Typography fontSize={18} fontWeight={600} align={'left'} sx={{ mt: 2, color: '#2B7FFC' }}>
                Races Played
              </Typography>
              <Typography fontSize={18} fontWeight={400} align={'left'}>
                {account?.statistics.race_played}
              </Typography>
              <Typography fontSize={18} fontWeight={600} align={'left'} sx={{ mt: 2, color: '#2B7FFC' }}>
                Stable Level
              </Typography>
              <Typography fontSize={18} fontWeight={400} align={'left'}>
                {account?.statistics.stable_level}
              </Typography>
              <Typography fontSize={18} fontWeight={600} align={'left'} sx={{ mt: 2, color: '#2B7FFC' }}>
                Tournaments Played
              </Typography>
              <Typography fontSize={18} fontWeight={400} align={'left'}>
                {account?.statistics.tournament_played}
              </Typography>
              <Typography fontSize={18} fontWeight={600} align={'left'} sx={{ mt: 2, color: '#2B7FFC' }}>
                Tournaments Tickets
              </Typography>
              <Typography fontSize={18} fontWeight={400} align={'left'}>
                {account?.statistics.tournament_tickets}
              </Typography>
              <Typography fontSize={18} fontWeight={600} align={'left'} sx={{ mt: 2, color: '#2B7FFC' }}>
                Achievements
              </Typography>
            </Grid>
            <Grid item className='no-left-padding' xs={6} sx={{ display: 'flex', flexDirection: 'column', paddingLeft: '0px' }}
            >
              <Typography fontSize={18} fontWeight={600} align={'right'} sx={{ color: '#2B7FFC' }}>
                XP
              </Typography>
              <Typography fontSize={18} fontWeight={400} align={'right'}>
                {account?.statistics.xp}
              </Typography>
              <Typography fontSize={18} fontWeight={600} align={'right'} sx={{ mt: 2, color: '#2B7FFC' }}>
                Win Rate
              </Typography>
              <Typography fontSize={18} fontWeight={400} align={'right'}>
                {account?.statistics.winrate}%
              </Typography>
              <Typography fontSize={18} fontWeight={600} align={'right'} sx={{ mt: 2, color: '#2B7FFC' }}>
                NFTs Owned
              </Typography>
              <Typography fontSize={18} fontWeight={400} align={'right'}>
                {account?.statistics.nfts}
              </Typography>
              <Typography fontSize={18} fontWeight={600} align={'right'} sx={{ mt: 2, color: '#2B7FFC' }}>
                $ESTAR Owned
              </Typography>
              <Typography fontSize={18} fontWeight={400} align={'right'}>
                {account?.statistics.estar}
              </Typography>
              <Typography fontSize={18} fontWeight={600} align={'right'} sx={{ mt: 2, color: '#2B7FFC' }}>
                Tournaments Won
              </Typography>
              <Typography fontSize={18} fontWeight={400} align={'right'}>
                {account?.statistics.tournament_won}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className='no-left-padding' sx={{ display: 'flex' }}>
            {account?.badges?.map((badge) => (
              <Grid item className='no-left-padding' xs={3} key={badge.name}>
                <BadgeImage badge={badge} />
              </Grid>
            ))}
          </Grid>
          <Grid
            container
            sx={{
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <ClaimRewards />
          </Grid>
        </Grid>
      </Grid>
    </>

  )
})

const TEST_HOOKS = {
  USERPROFILECONTENT: 'userProfileContent'
}

interface Props {
  profileUsername: string
}

export default UserProfileContent

export {
  UserProfileContent,
  TEST_HOOKS,
  type Props
}
