import { CreateTournament, EmitEvents, GenericResponse, ReceiveEvents, Tournament, TournamentDetails } from 'shared/types'

import BackendService from './BackendService'

export class TournamentsService extends BackendService {
  getTournamentsHistory (
    success: (data: Tournament[]) => void,
    error: (error: any) => void
  ): void {
    this.emit(EmitEvents.TournamentsHistory)
    this.on(ReceiveEvents.TournamentsHistory, success, error)
  }

  getTournaments (
    success: (data: Tournament[]) => void,
    error: (error: any) => void
  ): void {
    this.emit(EmitEvents.Tournaments)
    this.on(ReceiveEvents.Tournaments, success, error)
  }

  getTournament (
    id: string,
    success: (data: TournamentDetails) => void,
    error: (error: any) => void
  ): void {
    this.emit(EmitEvents.Tournament, id)
    this.on(ReceiveEvents.Tournament, success, error)
  }

  getUsername (
    address: string,
    success: (data: any) => void,
    error: (error: any) => void
  ): void {
    try {
      this.emitWithAck(EmitEvents.Username, success, address)
    } catch (exception: any) {
      error(exception)
    }
  }

  createTournament (
    createInput: CreateTournament,
    address: string,
    success: (data: any) => void,
    error: (error: any) => void
  ): void {
    this.emit(EmitEvents.CreateTournament, createInput, address)
    this.on(ReceiveEvents.CreateTournament, success, error)
  }

  checkTournament (
    tournamentId: string,
    address: string,
    success: (data: GenericResponse) => void,
    error: (error: any) => void
  ): void {
    try {
      this.emitWithAck(EmitEvents.CheckTournament, success, tournamentId, address)
    } catch (exception: any) {
      error(exception)
    }
  }

  enterTournament (
    address: string,
    horse: string,
    tournamentId: string,
    success: (data: GenericResponse) => void,
    error: (error: any) => void
  ): void {
    try {
      this.emitWithAck(EmitEvents.EnterTournament, success, address, horse, tournamentId)
    } catch (exception: any) {
      error(exception)
    }
  }

  getNfts (
    address: string,
    success: (data: any) => void,
    error: (error: any) => void
  ): void {
    try {
      this.emitWithAck(EmitEvents.GetNfts, success, address)
    } catch (exception: any) {
      error(exception)
    }
  }

  isAdmin (
    address: string,
    success: (data: any) => void,
    error: (error: any) => void
  ): void {
    this.emit(EmitEvents.IsAdmin, address)
    this.on(ReceiveEvents.IsAdmin, success, error)
  }

  removeTournamentsHistoryListener (): void {
    this.disconnect(ReceiveEvents.TournamentsHistory)
  }

  removeTournamentsListener (): void {
    this.disconnect(ReceiveEvents.Tournaments)
  }

  removeTournamentListener (): void {
    this.disconnect(ReceiveEvents.Tournament)
  }

  removeCreateTournamentListener (): void {
    this.disconnect(ReceiveEvents.CreateTournament)
  }

  removeIsAdminListener (): void {
    this.disconnect(ReceiveEvents.IsAdmin)
  }
}
