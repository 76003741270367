import React, { FC, useEffect } from 'react'

import { useGetAccount } from '@multiversx/sdk-dapp/hooks'
import { Loader } from '@multiversx/sdk-dapp/UI'
import { observer } from 'mobx-react-lite'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'

import { Status } from 'shared/resource'

import { DefaultLayout } from 'layouts'

import { useStores } from 'stores/hooks'

import Animation from './components/Animation'

const AnimationPage: FC = observer(() => {
  const { id } = useParams()
  const raceId = id === undefined ? undefined : decodeURIComponent(id)
  const { racesStore } = useStores()
  const { address } = useGetAccount()

  const raceResource = raceId === undefined ? undefined : racesStore.races.get(raceId)

  useEffect(() => {
    if (raceId === undefined) {
      return
    }

    racesStore.getRace(raceId, address)
    return () => {
      racesStore.removeGetRaceResource()
    }
  }, [racesStore, raceId, address])
  if (raceResource?.status === Status.LOADING) {
    <Loader className='loader' noText={true} />
  }
  return (
    <DefaultLayout
      data-testid={TEST_HOOKS.ANIMATIONPAGE}
      main={
        <>
          <Helmet title="Animation" />
           <Animation
            firstWinnerName={raceResource?.data?.race.player[0].horse}
            secondWinnerName={raceResource?.data?.race.player[1].horse}
            thirdWinnerName={raceResource?.data?.race.player[2].horse}
            fourWinnerName={raceResource?.data?.race.player[3].horse}
            fiveWinnerName={raceResource?.data?.race.player[4].horse}
            sixWinnerName={raceResource?.data?.race.player[5].horse}
            sevenWinnerName={raceResource?.data?.race.player[6].horse}
            eightWinnerName={raceResource?.data?.race.player[7].horse}
            />
        </>
      }
    />
  )
})

const TEST_HOOKS = {
  ANIMATIONPAGE: 'animationPage'
}

export default AnimationPage

export {
  AnimationPage,
  TEST_HOOKS
}
