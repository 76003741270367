/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { FC, useMemo } from 'react'

import { InfoOutlined } from '@mui/icons-material'
import {
  Box,
  Card,
  CardActions,
  CardContent,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material'
import { observer } from 'mobx-react-lite'

import { Farm1, Farm2, Farm3, Farm4, Farm5 } from 'shared/images/buildings'
import { Resource } from 'shared/resource'
import { FarmResponseType } from 'shared/types'

import lockIcon from '../../../shared/icons/lockIcon.svg'
import { getPrice } from '../hooks/getPrice'

import FarmCardButton from './FarmCardButton'

const FarmCard: FC<Props> = observer(({ farmResource }) => {
  const farmIcon = useMemo(() => {
    if (farmResource?.data?.level === undefined) {
      return null
    }

    switch (farmResource.data.level) {
      case 2: return <Farm2 style={{ maxHeight: '200px' }}/>
      case 3: return <Farm3 style={{ maxHeight: '200px' }}/>
      case 4: return <Farm4 style={{ maxHeight: '200px' }}/>
      case 5: return <Farm5 style={{ maxHeight: '200px' }}/>
      default: return <Farm1 style={{ maxHeight: '200px' }} />
    }
  }, [farmResource?.data?.level])

  if (farmResource?.data === undefined) {
    return null
  }

  return (
    <Card sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', minWidth: '340px' }}>
      <Box mt={2} sx={{ display: 'flex', flexDirection: 'row', alignContent: 'center', justifyContent: 'center' }}>
        <Typography fontSize={'24px'}>
          Farm - level {farmResource.data.level}
        </Typography>
        <Tooltip
          title={
            <Typography component="ul" sx={{ margin: 0, padding: 0, listStyle: 'none' }}>
              <li>Level 1 - 5 OAT Daily</li>
              <li>Level 2 - 10 OAT Daily</li>
              <li>Level 3 - 30 OAT Daily</li>
              <li>Level 4 - 100 OAT Daily</li>
              <li>Level 5 - 200 OAT Daily</li>
            </Typography>
          }
        >
          <IconButton color="secondary">
            <InfoOutlined />
          </IconButton>
        </Tooltip>
      </Box>
      <CardContent sx={{ textAlign: 'center', paddingBottom: '0px' }}>
        <Box position="relative">
          {(farmResource.data.level === 0)
            ? <>
              <img src={lockIcon}
                height={'80px'}
                style={{
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  margin: 'auto',
                  zIndex: 999
                }} alt="Your SVG" />
              <Farm1 style={{ maxHeight: '200px', opacity: '30%', zIndex: 900 }} />
              </>
            : farmIcon }
        </Box>
      </CardContent>
      <CardActions sx={{ display: 'flex', flexDirection: 'column' }}>
        {(farmResource?.data?.price !== undefined) &&
          <>
            <FarmCardButton level={farmResource.data.level} price={farmResource?.data?.price} token={'estar'} />
            <FarmCardButton level={farmResource.data.level} price={farmResource?.data?.price_in_egld} token={'egld'} />
          </>
        }
        <Typography mt={1}>Level Price: {getPrice(farmResource?.data?.price).toFixed(0)} ESTAR</Typography>
        <Typography mb={1}>Level Price: {getPrice(farmResource?.data?.price_in_egld)} EGLD</Typography>
      </CardActions>
    </Card>
  )
})

const TEST_HOOKS = {
  FARMCARD: 'farmCard'
}

// FIXME: Either add props or remove the interface!
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {
  farmResource: Resource<FarmResponseType> | undefined
}

export default FarmCard

export {
  FarmCard,
  TEST_HOOKS,
  type Props
}
