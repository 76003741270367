import React, { FC } from 'react'

import { Box, CircularProgress } from '@mui/material'
import { observer } from 'mobx-react-lite'

const LazyLoadingPage: FC<Props> = observer(() => {
  return (
    <Box
      data-testid={TEST_HOOKS.LAZYLOADINGPAGE}
      sx={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <CircularProgress size={64} />
    </Box>
  )
})

const TEST_HOOKS = {
  LAZYLOADINGPAGE: 'lazyLoadingPage'
}

// FIXME: Either add props or remove the interface!
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {

}

export default LazyLoadingPage

export {
  LazyLoadingPage,
  TEST_HOOKS,
  type Props
}
