import React, { FC, lazy } from 'react'

import { AccountBoxOutlined, Grid4x4Outlined, LoginOutlined } from '@mui/icons-material'
import { createBrowserRouter, Outlet, RouteObject } from 'react-router-dom'

import { Home, Race, Stable, Shop, Stadium, Logout, Globe, Stake } from 'shared/icons/menu'

import TournamentsDetailsPage from 'pages/TournamentsMainPage/components/TournamentsDetailsPage'

import { AuthenticatedOnlyWrapper } from 'components'

import AuthRedirectWrapper from 'components/AuthRedirectWrapper'

import EnterRacePage from '../pages/RacesPage/components/EnterRacePage'
import UserProfilePage from '../pages/RacesPage/components/UserProfilePage'

// Using lazy loading for code splitting
const DashboardPage = lazy(async () => await import('pages/DashboardPage'))
const RacesPage = lazy(async () => await import('pages/RacesPage'))
const TournamentsMainPage = lazy(async () => await import('pages/TournamentsMainPage'))
const MarketplacePage = lazy(async () => await import('pages/MarketplacePage'))
const BuildingsPage = lazy(async () => await import('pages/BuildingsPage'))
const LandingPage = lazy(async () => await import('pages/LandingPage'))
const LogoutPage = lazy(async () => await import('pages/LogoutPage'))
const ProfilePage = lazy(async () => await import('pages/ProfilePage'))
const ViewRace = lazy(async () => await import('pages/RacesPage/ViewRacePage'))
const StakingPage = lazy(async () => await import('pages/StakingPage'))
const LegacyAppPage = lazy(async () => await import('pages/LegacyAppPage'))
const AnimationPage = lazy(async () => await import('pages/AnimationPage'))
const ViewFinishedRace = lazy(async () => await import('pages/RacesPage/components/ViewRacePageContent/ViewFinishedRace'))

type ExtendedRouteObject = RouteObject & { name: string, icon: FC, spacerBefore?: boolean }

const appRoutes: ExtendedRouteObject[] = [
  {
    path: '/app',
    name: 'mainMenu.home',
    element: <DashboardPage />,
    icon: Home
  },
  {
    name: 'mainMenu.races',
    path: '/app/races',
    element: <RacesPage />,
    icon: Race
  },
  {
    name: 'mainMenu.tournaments',
    path: '/app/tournaments',
    element: <TournamentsMainPage />,
    icon: Stadium
  },
  {
    name: 'mainMenu.marketplace',
    path: '/app/marketplace',
    element: <MarketplacePage />,
    icon: Shop
  },
  {
    name: 'mainMenu.buildings',
    path: '/app/buildings',
    element: <BuildingsPage />,
    icon: Stable
  },
  // {
  //   name: 'mainMenu.tickets',
  //   path: '/app/tickets',
  //   element: null,
  //   icon: Ticket
  // },
  // {
  //   name: 'mainMenu.rules',
  //   path: '/app/rules',
  //   element: null,
  //   icon: Warning,
  //   spacerBefore: true
  // },
  // {
  //   name: 'mainMenu.other',
  //   path: '/app/other',
  //   element: null,
  //   icon: Document
  // },
  {
    name: 'Staking',
    path: '/app/staking',
    element: <StakingPage />,
    icon: Stake
  },
  {
    name: 'Legacy Version',
    path: '/app/legacy',
    element: <LegacyAppPage />,
    icon: Globe,
    spacerBefore: true
  }
]

const routes: ExtendedRouteObject[] = [
  {
    name: 'Login',
    path: '/',
    element: (
      <AuthRedirectWrapper>
        <LandingPage />,
      </AuthRedirectWrapper>
    ),
    icon: LoginOutlined
  },
  {
    name: 'App',
    path: '/app',
    element: (
      <AuthenticatedOnlyWrapper>
        <Outlet />
      </AuthenticatedOnlyWrapper>
    ),
    children: appRoutes,
    icon: Grid4x4Outlined
  },
  {
    name: 'Profile',
    path: '/app/profile',
    element: <ProfilePage />,
    icon: AccountBoxOutlined
  },
  {
    name: 'View Race',
    path: '/app/view/race/:id',
    element: <ViewRace />,
    icon: AccountBoxOutlined
  },
  {
    name: 'Enter Race',
    path: '/app/enter/race/:id',
    element: <EnterRacePage />,
    icon: AccountBoxOutlined
  },
  {
    name: 'Finished Race',
    path: '/app/view/result/race/:id',
    element: <ViewFinishedRace />,
    icon: AccountBoxOutlined
  },
  {
    name: 'User Profile page',
    path: '/app/account/:username',
    element: <UserProfilePage />,
    icon: AccountBoxOutlined
  },
  {
    name: 'User Profile page',
    path: '/app/view/tournament/:id',
    element: <TournamentsDetailsPage />,
    icon: AccountBoxOutlined
  },
  {
    name: 'Animation Race',
    path: '/app/view/race/animation/:id',
    element: <AnimationPage />,
    icon: AccountBoxOutlined
  },
  {
    name: 'Logout',
    path: '/logout',
    element: <LogoutPage />,
    icon: Logout,
    spacerBefore: true
  }
]

const router = createBrowserRouter(routes)

export {
  appRoutes,
  routes,
  router
}
