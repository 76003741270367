import { sendTransactions } from '@multiversx/sdk-dapp/services'
import { refreshAccount } from '@multiversx/sdk-dapp/utils'

import { RECEIVE_ADDRESS } from 'config'

import { RaceDoc, Token } from 'shared/types'
import { numHex } from 'shared/utils/numHex'
import { strHex } from 'shared/utils/strHex'
import useLocalStorage from 'shared/utils/useLocalStorage'

export const useGetEnterRaceTransaction = (): { enterRace: (race: RaceDoc, token: Token) => Promise<void>, transactionSessionId: string } => {
  const [transactionSessionId, setTransactionSessionId] = useLocalStorage<string | null>(
    'payTransactionSessionId',
    null
  )

  const enterRace = async (race: RaceDoc, token: Token): Promise<void> => {
    const transaction = {
      value: race.with === 'EGLD' ? race.entryFee * 1000000000000000000 : 0,
      gasLimit: '6000000',
      data:
        race.with === 'EGLD'
          ? Buffer.from('Enter')
          : Buffer.from(
            'ESDTTransfer@' +
            strHex(token.identifier) +
            '@' +
            numHex(race.entryFee * token.decimals) +
            '@456E746572'
          ),
      receiver: RECEIVE_ADDRESS
    }
    await refreshAccount()

    const { sessionId } = await sendTransactions({
      transactions: transaction,
      transactionsDisplayInfo: {
        processingMessage: 'Processing transaction',
        errorMessage: 'An error has occured during',
        successMessage: 'Transaction successful'
      },
      redirectAfterSign: false
    })
    if (sessionId != null) {
      setTransactionSessionId(sessionId)
    }
  }

  return { enterRace, transactionSessionId }
}
