import { makeAutoObservable } from 'mobx'
import { TournamentsService } from 'services'

import { Resource } from 'shared/resource'
import { Store, TournamentDetails } from 'shared/types'

class TournamentsDetailsStore implements Store {
  tournaments: Map<string, Resource<TournamentDetails> > = new Map()
  usernames: Map<string, Resource<string> > = new Map()

  tournamentsService: TournamentsService

  constructor (tournamentsService: TournamentsService) {
    this.tournamentsService = tournamentsService
    makeAutoObservable(this)
  }

  getTournament (id: string): any {
    this.tournaments.set(id, Resource.loading())
    this.tournamentsService.getTournament(id, (data) => {
      this.tournaments.set(data._id, Resource.success(data))
    }, (error) => {
      this.tournaments.set(id, Resource.error(error))
    })
  }

  getUsername (address: string): any {
    this.usernames.set(address, Resource.loading())
    this.tournamentsService.getUsername(address, (data) => {
      this.usernames.set(address, Resource.success(data))
    }, (error) => {
      this.usernames.set(address, Resource.error(error))
    })
  }

  reset (): void {
    this.tournamentsService.removeTournamentListener()
  }
}

export default TournamentsDetailsStore
