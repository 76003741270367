import React, { FC, ReactNode } from 'react'

import { Box } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { sidebarWidth } from 'shared/theme'

import { Sidebar, TopBar, Footer } from 'components'

const DefaultLayout: FC<Props> = ({ main, sidebar = null }) => {
  return (
    <Box data-testid={TEST_HOOKS.DEFAULTLAYOUT}>
      <TopBar />
      <Sidebar />
      <Box sx={{ marginLeft: `${sidebarWidth}px`, padding: 4, display: 'flex', flexDirection: 'column', minHeight: '90vh' }}>
        <Grid container spacing={8} sx={{ flexGrow: '1' }}>
          <Grid xs={12} lg={sidebar != null ? 8 : 12}>
            {main}
          </Grid>
          {sidebar != null && (
            <Grid xs={12} lg={4}>
              {sidebar}
            </Grid>
          )}
        </Grid>
        <Footer />
      </Box>
    </Box>
  )
}

const TEST_HOOKS = {
  DEFAULTLAYOUT: 'defaultLayout'
}

interface Props {
  sidebar?: ReactNode
  main: ReactNode
}

export default DefaultLayout

export {
  DefaultLayout,
  TEST_HOOKS,
  type Props
}
