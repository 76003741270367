import { styled, Typography } from '@mui/material'

import { textShadow } from 'shared/theme'

const TextHighlight = styled(Typography)(({ theme }) => ({
  textShadow: textShadow(),
  '&::first-letter': {
    color: theme.palette.primary.main
  }
})) as typeof Typography

export default TextHighlight

export {
  TextHighlight
}
