import React, { FC, useEffect } from 'react'

import { Button, CircularProgress, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { useTrackTransactionStatus } from '@multiversx/sdk-dapp/hooks'
import { observer } from 'mobx-react-lite'

import { RaceDoc, Token } from 'shared/types'

import { useGetEnterRaceTransaction } from './useGetEnterRaceTransaction'

const PayRace: FC<Props> = observer(({ raceDoc, token, setIsPaid }) => {
  const { enterRace, transactionSessionId } = useGetEnterRaceTransaction()

  const transactionStatus = useTrackTransactionStatus({
    transactionId: transactionSessionId
  })

  const handleEnterRace = (): void => {
    enterRace(raceDoc, token).catch((error: any) => {
      console.log(error)
    })
  }

  useEffect(() => {
    if (transactionStatus.isSuccessful !== undefined && transactionStatus.isSuccessful) {
      setIsPaid(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionStatus])

  return (
    <Box>
      <Typography fontSize={'20px'} fontWeight={400} sx={{ marginTop: '35px' }}>
        Pay {raceDoc.entryFee} {raceDoc.with} to ENTER the Race
      </Typography>
      <Button
        className="joinBtn"
        variant="contained"
        color="primary"
        onClick={handleEnterRace}
        sx={{
          borderRadius: 20,
          marginTop: '35px',
          padding: '25px 25px',
          maxHeight: '24px'
        }}
      >
        {transactionStatus.isPending !== undefined && transactionStatus.isPending ? <CircularProgress color="info" size={20} /> : 'Pay'}
      </Button>
    </Box>
  )
})

const TEST_HOOKS = {
  PAYRACE: 'payRace'
}

interface Props {
  raceDoc: RaceDoc
  token: Token
  setIsPaid: (value: boolean) => void
}

export default PayRace

export {
  PayRace,
  TEST_HOOKS,
  type Props
}
