import React, { FC, useEffect, useMemo } from 'react'

import { Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'

import { Player } from 'shared/types'
import { isValidAddress } from 'shared/utils/isValidAddress'

import { useStores } from 'stores/hooks'

const Username: FC<Props> = observer(({ player }) => {
  const { tournamentsDetailsStore } = useStores()

  const userAddress = player.address

  useEffect(() => {
    tournamentsDetailsStore.getUsername(userAddress)
  }, [tournamentsDetailsStore, userAddress])

  const username = tournamentsDetailsStore.usernames.get(userAddress)?.data

  const formattedUsername = useMemo(() => {
    if (username === undefined) {
      return ''
    }

    if (isValidAddress(username)) {
      return username.slice(0, 3) + '...' + username.slice(-3)
    }
    return username
  }, [username])

  return (
    <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>
      {formattedUsername}
    </Typography>
  )
})

const TEST_HOOKS = {
  USERNAME: 'username'
}

interface Props {
  player: Player
}

export default Username

export {
  Username,
  TEST_HOOKS,
  type Props
}
