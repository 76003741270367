import React, { FC } from 'react'

import { Typography, Button } from '@mui/material'
import { useGetAccount } from '@multiversx/sdk-dapp/hooks'

import { fetchRewards, sendClaimTransaction } from '../../../hooks'

const ClaimRewards: FC<Props> = ({ scAddress }) => {
  const { address } = useGetAccount()
  const [rewards, setRewards] = React.useState<number>(0)

  React.useEffect(() => {
    fetchRewards(scAddress, address).then((rewards) => setRewards(rewards)).catch((err) => console.log(err))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <>
    <Typography variant="h6" sx={{ marginBottom: '2px' }}>
      Your reward: {rewards} $ESTAR
    </Typography>
    <Button
      variant="contained"
      color="primary"
      sx={{
        borderRadius: 20,
        marginTop: '11px',
        marginBottom: '12px',
        padding: '16px 12px',
        maxHeight: '20px'
      }}
      // eslint-disable-next-line
      onClick={() => sendClaimTransaction(scAddress, 7_000_000)}
      disabled={rewards === 0}
    >
      <Typography fontSize={ '15px' } fontWeight={ 500 }>
        Claim Rewards
      </Typography>
    </Button>
  </>
}

interface Props {
  scAddress: string
}

export default ClaimRewards
