import React, { FC } from 'react'

import { Badge } from 'shared/types'

const BadgeImage: FC<Props> = ({ badge }) => {
  return (
    <img
      key={badge.name}
      src={`${window.location.origin.toString()}/${badge.path}`}
      alt={badge.name}
      style={{ height: 100, width: 100 }}
    />
  )
}

interface Props {
  badge: Badge
}

export default BadgeImage

export {
  BadgeImage,
  type Props
}
