import React, { FC, useEffect, useState } from 'react'

import { Grid, IconButton } from '@mui/material'
import { Loader } from '@multiversx/sdk-dapp/UI'
import { observer } from 'mobx-react-lite'
import { Helmet } from 'react-helmet'
import { useNavigate, useParams } from 'react-router-dom'

import { Status } from 'shared/resource'

import { DefaultLayout } from 'layouts'

import { useStores } from 'stores/hooks'

import { Aside, TextHighlight } from 'components'

import doubleChevron from '../../../../shared/icons/doubleChevron.svg'

import StageButtons from './components/StageButtons'
import ViewStage from './components/ViewStage'
import { tournamentToViewStage } from './helpers'

export enum TournamentStage {
  WINNERS = 'WINNERS',
  STAGE1 = 'STAGE 1',
  STAGE2 = 'STAGE 2',
}

const TournamentsDetailsPage: FC = observer(() => {
  const navigate = useNavigate()
  const { id } = useParams()
  const tournamentId = id === undefined ? '' : decodeURIComponent(id)

  const { tournamentsDetailsStore } = useStores()

  const [currentStage, setCurrentStage] = useState(TournamentStage.WINNERS)
  const [firstButtonStage, setFirstButtonStage] = useState(TournamentStage.STAGE1)
  const [secondButtonStage, setSecondButtonStage] = useState(TournamentStage.STAGE2)

  const tournamentResource = tournamentsDetailsStore.tournaments.get(tournamentId)

  useEffect(() => {
    tournamentsDetailsStore.getTournament(tournamentId)

    return () => {
      tournamentsDetailsStore.reset()
    }
  }, [tournamentId, tournamentsDetailsStore])

  const handleFirstButtonClick = (): void => {
    setCurrentStage((prevSelected) => {
      setFirstButtonStage(prevSelected)
      return firstButtonStage
    })
  }

  const handleSecondButtonClick = (): void => {
    setCurrentStage((prevSelected) => {
      setSecondButtonStage(prevSelected)
      return secondButtonStage
    })
  }

  if (tournamentResource?.status === Status.LOADING) {
    return <Loader className='loader centered' noText={true} />
  }

  return (
    <DefaultLayout
      main={
        <>
          <Helmet title="Tournament Details" />
          <Grid alignItems="center" sx={{ display: 'flex', flexDirection: 'row' }}>
            <IconButton onClick={() => { navigate('/app/tournaments') }} size="small" color="primary" aria-label="back button" sx={{ mr: 2 }} >
              <img src={doubleChevron} alt="Your SVG" width="32px" height="32px" />
            </IconButton>
            <TextHighlight variant="h2">
              Estar#{tournamentId} Tournament
            </TextHighlight>
          </Grid>
          <ViewStage stage={currentStage} tournament={tournamentToViewStage(tournamentResource?.data)} />
          <StageButtons onFirstButtonClick={() => handleFirstButtonClick()} firstButtonStage={firstButtonStage} onSecondButtonClick={() => handleSecondButtonClick()} secondButtonStage={secondButtonStage} />
        </>
      }
      sidebar={<Aside />}
    />

  )
})

const TEST_HOOKS = {
  TOURNAMENTSDETAILSPAGE: 'tournamentsDetailsPage'
}

export default TournamentsDetailsPage

export {
  TournamentsDetailsPage,
  TEST_HOOKS
}
