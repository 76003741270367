import React, { FC } from 'react'

import { observer } from 'mobx-react-lite'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'

import { DefaultLayout } from 'layouts'

import { Aside } from 'components'

import UserProfileContent from './UserProfileContent'

const UserProfilePage: FC<Props> = observer(() => {
  const { username } = useParams()
  const profileUsername = username === undefined ? '' : username

  return (
    <DefaultLayout
      main={
        <>
          <Helmet title="Races" />
          <UserProfileContent profileUsername={profileUsername} />
        </>
      }
      sidebar={<Aside />}
    />
  )
})

const TEST_HOOKS = {
  USERPROFILEPAGE: 'userProfilePage'
}

// FIXME: Either add props or remove the interface!
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {

}

export default UserProfilePage

export {
  UserProfilePage,
  TEST_HOOKS,
  type Props
}
