import React, { useEffect, useState } from 'react'

import './Animation.css'

import { observer } from 'mobx-react-lite'

import { getNftUrl, isFreeHorse } from 'shared/utils'

import { useStores } from 'stores/hooks'

const Animation = ({ firstWinnerName, secondWinnerName, thirdWinnerName, fourWinnerName, fiveWinnerName, sixWinnerName, sevenWinnerName, eightWinnerName }: { firstWinnerName?: string, secondWinnerName?: string, thirdWinnerName?: string, fourWinnerName?: string, fiveWinnerName?: string, sixWinnerName?: string, sevenWinnerName?: string, eightWinnerName?: string }): JSX.Element => {
  const [showNumber, setShowNumber] = useState<number>(0)

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowNumber((prevNumber) => prevNumber + 1)
    }, showNumber === 0 ? 18000 : 1000)

    return () => {
      clearTimeout(timer)
    }
  }, [showNumber])

  return (
    <div className="">
     <table>
  <thead>
    <tr>
      <th>Gate</th>
      <th></th>
      <th>Final Race</th>
    </tr>
  </thead>
  <tbody className="canvas">
    <tr>
      <td><span className="number-list">1</span> </td>
      <td className="test"><AnimationImage winnerName={fiveWinnerName !== undefined ? fiveWinnerName : ''} classes={'image image-5'}/></td>
      <td><span className={`litera-dreapta litera5${showNumber >= 5 ? ' visible' : ''}`}>5</span></td>
    </tr>
    <tr>
      <td><span className="number-list">2</span></td>
      <td className="test"><AnimationImage winnerName={thirdWinnerName !== undefined ? thirdWinnerName : ''} classes={'image image-3'}/></td>
      <td><span className={`litera-dreapta litera3${showNumber >= 3 ? ' visible' : ''}`}>3</span></td>
    </tr>
    <tr>
      <td><span className="number-list">3</span></td>
      <td className="test"><AnimationImage winnerName={fourWinnerName !== undefined ? fourWinnerName : ''} classes={'image image-4'}/></td>
      <td><span className={`litera-dreapta litera4${showNumber >= 4 ? ' visible' : ''}`}>4</span></td>
    </tr>
    <tr>
      <td><span className="number-list">4</span></td>
      <td className="test"><AnimationImage winnerName={sevenWinnerName !== undefined ? sevenWinnerName : ''} classes={'image image-7'}/></td>
      <td><span className={`litera-dreapta litera7${showNumber >= 7 ? ' visible' : ''}`}>7</span></td>
    </tr>
    <tr>
      <td><span className="number-list">5</span></td>
      <td className="test"><AnimationImage winnerName={firstWinnerName !== undefined ? firstWinnerName : ''} classes={'image image-1'}/></td>
      <td><span className={`litera-dreapta litera1${showNumber >= 1 ? ' visible' : ''}`}>1</span> </td>
    </tr>
    <tr>
      <td><span className="number-list">6</span></td>
      <td className="test"><AnimationImage winnerName={eightWinnerName !== undefined ? eightWinnerName : ''} classes={'image image-8'}/></td>
      <td><span className={`litera-dreapta litera8${showNumber >= 8 ? ' visible' : ''}`}>8</span></td>
    </tr>
    <tr>
      <td><span className="number-list">7</span></td>
      <td className="test"><AnimationImage winnerName={secondWinnerName !== undefined ? secondWinnerName : ''} classes={'image image-2'}/></td>
      <td><span className={`litera-dreapta litera2${showNumber >= 2 ? ' visible' : ''}`}>2</span></td>
    </tr>
    <tr>
      <td><span className="number-list">8</span></td>
      <td className="test"><AnimationImage winnerName={sixWinnerName !== undefined ? sixWinnerName : ''} classes={'image image-6'}/></td>
      <td><span className={`litera-dreapta litera6${showNumber >= 6 ? ' visible' : ''}`}>6</span></td>
    </tr>
  </tbody>
</table>

    </div>
  )
}

const AnimationImage = observer(({ winnerName, classes }: { winnerName: string, classes: string }) => {
  const { racesStore } = useStores()
  const horseResource = racesStore.horses.get(winnerName)

  useEffect(() => {
    racesStore.getHorse(winnerName)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [winnerName])
  return (
    <img className={classes} src={isFreeHorse(winnerName) ? horseResource?.data?.media : getNftUrl(winnerName, horseResource?.data?.collectionId)} alt="" />
  )
})

export default Animation
