import { Account, BuyMarketItem, CheckResponse, ClaimableReward, EmitEvents, GenericResponse, MarketItemType, ReceiveEvents, StableResponse, StatusNft } from 'shared/types'

import BackendService from './BackendService'

export class AccountService extends BackendService {
  getAccount (
    address: string,
    success: (data: Account) => void,
    error: (error: any) => void
  ): void {
    this.emit(EmitEvents.LoadAccount, address)
    this.on(ReceiveEvents.GetAccount, success, error)
  }

  getStatus (
    address: string,
    success: (data: StatusNft[]) => void,
    error: (error: any) => void
  ): void {
    this.emit(EmitEvents.GetStatus, address)
    this.on(ReceiveEvents.GetStatus, success, error)
  }

  createAccount (
    address: string,
    username: string,
    success: (data: string) => void,
    error: (error: any) => void
  ): void {
    this.emit(EmitEvents.CreateAccount, { address, username })
    this.on(ReceiveEvents.CreateAccount, success, error)
  }

  editAccount (
    address: string,
    username: string,
    success: (data: string) => void,
    error: (error: any) => void
  ): void {
    this.emit(EmitEvents.EditAccount, { address, username })
    this.on(ReceiveEvents.EditAccount, success, error)
  }

  getMarketItems (
    success: (data: MarketItemType[]) => void,
    error: (error: any) => void
  ): void {
    this.emit(EmitEvents.GetMarketItems)
    this.on(ReceiveEvents.GetMarketItems, success, error)
  }

  getRewards (
    address: string,
    success: (data: ClaimableReward[] | null) => void,
    error: (error: any) => void
  ): void {
    try {
      this.emitWithAck(EmitEvents.GetRewards, success, address)
    } catch (exception: any) {
      error(exception)
    }
  }

  getStable (
    address: string,
    success: (data: StableResponse) => void,
    error: (error: any) => void
  ): void {
    try {
      this.emitWithAck(EmitEvents.GetStable, success, address)
    } catch (exception: any) {
      error(exception)
    }
  }

  upgradeStable (
    address: string,
    success: (data: any) => void,
    error: (error: any) => void
  ): void {
    try {
      this.emitWithAck(EmitEvents.UpgradeStable, success, address)
    } catch (exception: any) {
      error(exception)
    }
  }

  buyMarketItem (
    item: BuyMarketItem,
    address: string,
    success: (data: any) => void,
    error: (error: any) => void
  ): void {
    try {
      this.emitWithAck(EmitEvents.BuyMarketItem, success, item, address)
    } catch (exception: any) {
      error(exception)
    }
  }

  checkIfClaimedFreeHorses (
    address: string,
    success: (data: CheckResponse) => void,
    error: (error: any) => void
  ): void {
    try {
      this.emitWithAck(EmitEvents.CheckIfClaimedFreeHorses, success, address)
    } catch (exception: any) {
      error(exception)
    }
  }

  claimFreeHorses (
    address: string,
    success: (data: GenericResponse) => void,
    error: (error: any) => void
  ): void {
    try {
      this.emitWithAck(EmitEvents.ClaimFreeHorses, success, { address })
    } catch (exception: any) {
      error(exception)
    }
  }

  removeGetAccountListener (): void {
    this.disconnect(ReceiveEvents.GetAccount)
  }

  removeGetMarketItemsListener (): void {
    this.disconnect(ReceiveEvents.GetMarketItems)
  }

  removeGetStatusListener (): void {
    this.disconnect(ReceiveEvents.GetStatus)
  }

  removeCreateAccountListener (): void {
    this.disconnect(ReceiveEvents.CreateAccount)
  }

  removeEditAccountListener (): void {
    this.disconnect(ReceiveEvents.EditAccount)
  }
}
