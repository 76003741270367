import { FC, SVGProps } from 'react'

import { Socket } from 'socket.io-client'

type Currency = 'ESTAR' | 'EGLD' | 'STAMINA' | 'XLH'

enum EmitEvents {
  AllRaces = 'get-all_history',
  TournamentsHistory = 'get-tournaments-history',
  Tournaments = 'get-tournaments',
  Tournament = 'get-tournament',
  Username = 'get-username',
  Horse = 'get-horse',
  CreateTournament = 'create-tournament',
  CheckTournament = 'get-tournament-check',
  GetNfts = 'get-nfts',
  EnterTournament = 'enter-tournament',
  IsAdmin = 'get-is-admin',
  LoadAccount = 'load-account',
  GetStatus = 'get-status',
  CreateAccount = 'create-account',
  EditAccount = 'edit-account',
  GetRewards = 'get-rewards',
  CheckIfClaimedFreeHorses = 'check-if-claimed-free-horse',
  ClaimFreeHorses = 'claim-free-for-all-horse',
  GetRaces = 'get-races',
  GetStable = 'get-stable',
  EnterRace = 'enter-race',
  GetRace = 'get-race',
  GetAvailable = 'get-available',
  GetUserProfile = 'get-account-profile',
  GetFarm = 'get-farm',
  UnlockFarm = 'unlock-farm',
  UpgradeFarm = 'upgrade-farm',
  UpgradeStable = 'upgrade-stable',
  GetMarketItems = 'get-market-items',
  BuyMarketItem = 'buy-marketplace-item',
  GetChristmasEvent = 'get-christmas-event',
  ClaimChristmasEvent = 'claim-christmas-event-item'
}

enum ReceiveEvents {
  AllRaces = 'recive-all_history',
  TournamentsHistory = 'recive-tournaments-history',
  Tournaments = 'recive-tournaments',
  Tournament = 'recive-tournament',
  CreateTournament = 'recive-create-tournament-response',
  IsAdmin = 'recive-is-admin',
  GetAccount = 'get-account',
  GetStatus = 'recive-status',
  CreateAccount = 'create-account_response',
  EditAccount = 'edit-account_response',
  GetRaces = 'recive-races',
  GetRace = 'recive-race',
  GetMarketItems = 'recive-market-items'
}
enum ResponseStatus {
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL'
}
enum RacesBulkType {
  STAMINA = 'STAMINA',
  ESTAR = 'ESTAR',
  EGLD = 'EGLD',
  CUSTOM = 'RARITY'
}
interface Money {
  amount: number
  precision?: number
  currency: Currency | string
}

interface EmitData {
  name: EmitEvents
  params: any
}

interface ReceiveData {
  name: ReceiveEvents
  callback: GenericFallback
}

type GenericFallback = (data: any) => any

type SvgIconComponent = FC<SVGProps<SVGSVGElement>>

interface Store {
  reset: () => any
}
interface IBackendService {
  backendConnectionString: string
  socket: Socket
  disconnect: (...args: any) => void
  emit: (...args: any) => void
}
interface FarmResponseType {
  address: string
  level: number
  wheat: number
  price: number
  price_in_egld: number
  wheatTight: number
};
interface MarketItemType {
  available: number
  date: string
  description: string
  img: string
  maxHolders: number
  maxPerUser: number
  name: string
  price: number
  price_in_egld: number
  quantity: number
  ref: string
  token: string
  _id: string
}
interface BuyMarketItem {
  name: string
  available: number
  quantity: number
  ref: string
  _id: string
}
interface Tournament {
  date: string
  description: string
  end: string
  entryFee: number
  isActive: boolean
  maxPlayers: number
  player: number
  prizeInToken: string
  prizePool: number
  rarity: string[]
  stage: number
  stages: Stage[]
  stamina: number
  type: string
  winners: Player[]
  youtube?: string
  _id: string
}
interface ViewStageTournament {
  maxPlayers: number
  players: number
  prizeInToken: string
  prizePool: number
  stages: Stage[]
  winners: Player[]
  _id: string
}
interface TournamentDetails {
  created_at: string
  date: string
  description: string
  end: string
  entryFee: number
  maxPlayers: number
  players: Player[]
  prizeInToken: string
  prizePool: number
  rarity: string[]
  stages: Stage[]
  stamina: number
  type: string
  winners: Player[]
  youtube?: string
  _v: number
  _id: string
}
interface Stage {
  players: Player[]
  stage: number
}
interface Player {
  address: string
  date: string
  horse: string
  position: number
  score: number
}
interface HorseSft {
  name: string
  media: string
  rarity: string
  speed: number
  endurance: number
  agility: number
  luck: number
  inRace: boolean
  race: string
  collectionId: string
}
interface CreateTournament {
  entryFee: number
  prizePool: number
  stamina: number
  prizeInToken: string
  maxPlayers: number
  end: string
  type: string
  description?: string
  isActive: boolean
  rarity: string[]
}
interface GenericResponse {
  status: ResponseStatus
  message: string
}
interface CheckResponse {
  claimed: boolean
}
interface ClaimableReward {
  token: string
  identifier: string
  quantity: number
  decimals: number
}
interface NftDetails {
  name: string
  rarity: string
  inRace: boolean
  stamina: number
  media?: string
  collectionId?: string
}
interface Account {
  data: AccountData
  message: string
  statistics: AccountStatistics
}
interface AccountStatistics {
  estar: number
  level: number
  nfts: number
  race_played: number
  race_won: number
  stable_level: number
  tournament_played: number
  tournament_tickets: number
  tournament_won: number
  winrate: number
  xp: number
}
interface StatusNft {
  name: string
  stamina: number
  inRace: boolean
  race: string
  rarity: string
  media?: string
  collectionId?: string
}
interface StableResponse {
  level: number
  price: string
  price_in_egld: number
  staminaMax: number
  next_level_price: number
  next_level_price_in_egld: number
}
interface Account {
  data: AccountData
  message: string
  statistics: AccountStatistics
}
interface AccountData {
  address: string
  admin: boolean
  badges: Badge[]
  inventory: []
  level: number
  username: string
  xp: number
  __v: number
  id: string
}
interface Badge {
  name: string
  path: string
}
interface AccountStatistics {
  estar: number
  level: number
  nfts: number
  race_played: number
  race_won: number
  stable_level: number
  tournament_played: number
  tournament_tickets: number
  tournament_won: number
  winrate: number
  xp: number
}
interface RacesData {
  races: RaceObject[]
  type: string
}
interface RaceObject {
  $__: any
  $isNew: boolean
  _doc: RaceDoc
}
interface RaceDoc {
  bonus: string
  date: string
  entryFee: number
  maxEntryPerWallet: number
  player: RacePlayer[]
  winners?: RaceWinner[]
  prizePool: number
  rarity: string[]
  stamina: number
  startedAt: number
  with: string
  __v: number
  _id: string
}
interface RacePlayer {
  address: string
  date: string
  score?: number
  entryFee: number
  horse: string
  raceId: string
  with: string
}
interface RaceWinner {
  positon: number
  address: string
  horse: string
  score: number
  date: string
}
interface GetRaceData {
  authorized: boolean
  race: RaceDoc
  status: boolean
}
interface EnterRaceData {
  address: string
  horse: string
  feePaid: boolean
  entryFee: number
  with: string
  raceId: string
}
interface Token {
  token: string
  identifier: string
  decimals: number
}
interface GetAvailableResponse {
  status: string
  race: RaceDoc
  message: string
}
interface GetUserProfileResponse {
  data: UserProfileData
  status: string
}
interface UserProfileData {
  address: string
  badges: Badge[]
  statistics: AccountStatistics
  username: string
}
interface StatusNft {
  name: string
  stamina: number
  inRace: boolean
  race: string
  rarity: string
  media?: string
}
interface StableResponse {
  curLevel: number
  message: string
  nextLevel: number
  nextLevelPrice: number
  nextLevelPriceHash: string
  nextLevelStaminaMax: number
  staminaMax: number
}
interface Account {
  data: AccountData
  message: string
  statistics: AccountStatistics
}
interface AccountData {
  address: string
  admin: boolean
  badges: Badge[]
  inventory: []
  level: number
  username: string
  xp: number
  __v: number
  id: string
}
interface Badge {
  name: string
  path: string
}
interface AccountStatistics {
  estar: number
  level: number
  nfts: number
  race_played: number
  race_won: number
  stable_level: number
  tournament_played: number
  tournament_tickets: number
  tournament_won: number
  winrate: number
  xp: number
}
interface RacesData {
  races: RaceObject[]
  type: string
}
interface RaceObject {
  $__: any
  $isNew: boolean
  _doc: RaceDoc
}
interface RaceDoc {
  bonus: string
  date: string
  entryFee: number
  maxEntryPerWallet: number
  player: RacePlayer[]
  winners?: RaceWinner[]
  prizePool: number
  rarity: string[]
  stamina: number
  startedAt: number
  with: string
  __v: number
  _id: string
}
interface RacePlayer {
  address: string
  date: string
  score?: number
  entryFee: number
  horse: string
  raceId: string
  with: string
}
interface RaceWinner {
  positon: number
  address: string
  horse: string
  score: number
  date: string
}
interface GetRaceData {
  authorized: boolean
  race: RaceDoc
  status: boolean
}
interface EnterRaceData {
  address: string
  horse: string
  feePaid: boolean
  entryFee: number
  with: string
  raceId: string
}
interface Token {
  token: string
  identifier: string
  decimals: number
}
interface GetAvailableResponse {
  status: string
  race: RaceDoc
  message: string
}
interface GetUserProfileResponse {
  data: UserProfileData
  status: string
}
interface UserProfileData {
  address: string
  badges: Badge[]
  statistics: AccountStatistics
  username: string
}
interface StatusNft {
  name: string
  stamina: number
  inRace: boolean
  race: string
  rarity: string
  media?: string
}
interface StableResponse {
  curLevel: number
  message: string
  nextLevel: number
  nextLevelPrice: number
  nextLevelPriceHash: string
  nextLevelStaminaMax: number
  staminaMax: number
}
interface Account {
  data: AccountData
  message: string
  statistics: AccountStatistics
}
interface AccountData {
  address: string
  admin: boolean
  badges: Badge[]
  inventory: []
  level: number
  username: string
  xp: number
  __v: number
  id: string
}
interface Badge {
  name: string
  path: string
}
interface AccountStatistics {
  estar: number
  level: number
  nfts: number
  race_played: number
  race_won: number
  stable_level: number
  tournament_played: number
  tournament_tickets: number
  tournament_won: number
  winrate: number
  xp: number
}
interface RacesData {
  races: RaceObject[]
  type: string
}
interface RaceObject {
  $__: any
  $isNew: boolean
  _doc: RaceDoc
}
interface RaceDoc {
  bonus: string
  date: string
  entryFee: number
  maxEntryPerWallet: number
  player: RacePlayer[]
  winners?: RaceWinner[]
  prizePool: number
  rarity: string[]
  stamina: number
  startedAt: number
  with: string
  __v: number
  _id: string
}
interface RacePlayer {
  address: string
  date: string
  score?: number
  entryFee: number
  horse: string
  raceId: string
  with: string
}
interface RaceWinner {
  positon: number
  address: string
  horse: string
  score: number
  date: string
}
interface GetRaceData {
  authorized: boolean
  race: RaceDoc
  status: boolean
}
interface EnterRaceData {
  address: string
  horse: string
  feePaid: boolean
  entryFee: number
  with: string
  raceId: string
}
interface Token {
  token: string
  identifier: string
  decimals: number
}
interface GetAvailableResponse {
  status: string
  race: RaceDoc
  message: string
}
interface GetUserProfileResponse {
  data: UserProfileData
  status: string
}
interface UserProfileData {
  address: string
  badges: Badge[]
  statistics: AccountStatistics
  username: string
}
enum TournamentType {
  DAILY = 'Daily',
  WEEKEND = 'Weekend'
}
enum CreateAccountResponse {
  USER_EXIST = 'USER_EXIST',
  SUCCESS = 'SUCCESS'
}
enum TournamentRarity {
  COMMON = 'COMMON',
  COMMONGOLD = 'COMMON GOLD',
  RARE = 'RARE',
  ULTRARARE = 'ULTRA RARE',
  EPIC = 'EPIC',
  LEGENDARY = 'LEGENDARY'
}

interface QuestRequirement {
  type: string
  amount: number
}

interface QuestPrize {
  type: string
  quantity: number
}

interface Quest {
  id: number
  requirements: QuestRequirement
  prize: QuestPrize
  available: boolean
  collected: boolean
}

interface QuestsResponse {
  status: 'FAIL' | 'SUCCESS'
  data?: {
    actualNbOfRaces: number
    day: string
    quests: Quest[]
  }
  message?: string
}

export {
  type Currency,
  type Money,
  type EmitData,
  type ReceiveData,
  type GenericFallback,
  type SvgIconComponent,
  type Store,
  type IBackendService,
  type Tournament,
  type MarketItemType,
  type ViewStageTournament,
  type TournamentDetails,
  type Player,
  type Stage,
  type CreateTournament,
  type GenericResponse,
  type CheckResponse,
  type NftDetails,
  type StatusNft,
  type Account,
  type BuyMarketItem,
  type ClaimableReward,
  type RacesData,
  type RaceDoc,
  type RaceObject,
  type RacePlayer,
  type RaceWinner,
  type Badge,
  type StableResponse,
  type EnterRaceData,
  type Token,
  type GetAvailableResponse,
  type GetRaceData,
  type GetUserProfileResponse,
  type HorseSft,
  type FarmResponseType,
  type QuestsResponse,
  type Quest,
  ResponseStatus,
  EmitEvents,
  ReceiveEvents,
  TournamentType,
  TournamentRarity,
  CreateAccountResponse,
  RacesBulkType
}
