import React, { FC, useEffect } from 'react'

import {
  Box,
  Grid
} from '@mui/material'
import { useGetAccount } from '@multiversx/sdk-dapp/hooks'
import { Loader } from '@multiversx/sdk-dapp/UI'
import { observer } from 'mobx-react-lite'

import { Status } from 'shared/resource'

import { useStores } from 'stores/hooks'

import FarmCard from './FarmCard'
import StableCard from './StableCard'

const BuildingsPageContent: FC<Props> = observer(() => {
  const { buildingsStore, accountStore } = useStores()
  const { address } = useGetAccount()

  useEffect(() => {
    buildingsStore.getFarm(address)
    accountStore.getStable(address)

    return () => {
      buildingsStore.resetFarmResource()
    }
  }, [accountStore, address, buildingsStore])

  const farmResource = buildingsStore.farmResource
  const stableResource = accountStore.stableResource

  if (buildingsStore.farmResource?.status === Status.LOADING || accountStore.stableResource?.status === Status.LOADING) {
    <Box sx={{ p: 4 }}>
      <Loader className='loader' noText={true} />
    </Box>
  }

  return (
    <Grid container mt={2}>
      <Grid item xs={12} md={6} lg={6} xl={4.5} sx={{ display: 'flex ', justifyContent: 'center' }}>
        <StableCard stableResource={stableResource} />
      </Grid>
      <Grid item xs={12} md={6} lg={6} xl={4.5} sx={{ display: 'flex ', justifyContent: 'center' }}>
        <FarmCard farmResource={farmResource} />
      </Grid>
    </Grid>
  )
})

const TEST_HOOKS = {
  BUILDINGSPAGECONTENT: 'buildingsPageContent'
}

// FIXME: Either add props or remove the interface!
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {

}

export default BuildingsPageContent

export {
  BuildingsPageContent,
  TEST_HOOKS,
  type Props
}
