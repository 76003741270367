import React, { FC, useEffect, useMemo, useState } from 'react'

import { Box, ImageList, Typography, useMediaQuery } from '@mui/material'
import { useGetAccount } from '@multiversx/sdk-dapp/hooks'
import { Loader } from '@multiversx/sdk-dapp/UI'
import { observer } from 'mobx-react-lite'

import { Status } from 'shared/resource'
import { GenericResponse, NftDetails, Tournament } from 'shared/types'

import { useStores } from 'stores/hooks'

import NftsDisplay from './NftsDisplay'

const EnterTournament: FC<Props> = observer(({ tournament }) => {
  const [nfts, setNfts] = useState<NftDetails[]>([])

  const isMobile = useMediaQuery('(max-width: 600px)')
  const isTablet = useMediaQuery('(max-width: 960px)')

  const cols = useMemo(() => {
    if (isMobile) {
      return 2
    }

    if (isTablet) {
      return 3
    }

    return 4
  }, [isMobile, isTablet])

  const { nftsStore, tournamentsStore } = useStores()

  const { address } = useGetAccount()

  useEffect(() => {
    nftsStore.getNfts(address)
    return () => {
      tournamentsStore.resetEnterTournamentResource()
    }
  }, [nftsStore, address, tournamentsStore])

  const accountNfts = nftsStore.nftsResource?.data
  const enterTournamentResource = tournamentsStore.enterTournamentResource

  const eligibleNfts = useMemo(() => {
    return accountNfts?.filter((nft: NftDetails) => {
      return (nft.stamina >= tournament.stamina && !nft.inRace && tournament.rarity.includes(nft.rarity.toUpperCase()))
    }) ?? []
  }, [accountNfts, tournament.rarity, tournament.stamina])

  useEffect(() => {
    setNfts(eligibleNfts)
  }, [eligibleNfts])

  useEffect(() => {
    const horseName = enterTournamentResource?.data
    if (horseName === undefined) {
      return
    }

    setNfts((prev) => prev.filter((item) => item.name !== horseName))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enterTournamentResource?.data])

  const enterTournament = (horse: string): void => {
    tournamentsStore.enterTournament(address, horse, tournament._id)
  }

  if (nftsStore.nftsResource?.status === Status.LOADING) {
    <Box sx={{ p: 4 }}>
      <Loader className='loader' noText={true} />
    </Box>
  }

  if (nfts === undefined || nfts.length === 0) {
    return (
      <Typography sx={{ textAlign: 'center', p: 4 }} id="modal-modal-title" variant="h6" component="h2">
        You do not have eligible nfts.
      </Typography>
    )
  }

  if (enterTournamentResource?.error !== undefined) {
    return (
      <Typography sx={{ textAlign: 'center', p: 4 }} id="modal-modal-title" variant="h6" component="h2">
        {(enterTournamentResource.error as GenericResponse).message}
      </Typography>
    )
  }

  return (
    <>
      <ImageList cols={cols} gap={10}>
        {nfts.map((nft) => (
          <NftsDisplay nftDetails={nft} key={nft.name} onNftClick={(name) => enterTournament(name)} />
        ))}
      </ImageList>
    </>
  )
})

const TEST_HOOKS = {
  ENTERTOURNAMENT: 'enterTournament'
}

interface Props {
  tournament: Tournament
}

export default EnterTournament

export {
  EnterTournament,
  TEST_HOOKS,
  type Props
}
