import React, { FC } from 'react'

import {
  Drawer as MuiDrawer,
  styled,
  Toolbar
} from '@mui/material'

import { sidebarWidth } from 'shared/theme'

import { MainMenu } from 'components'

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  {
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    '& .MuiDrawer-paper': {
      width: `${sidebarWidth}px`,
      background: 'transparent',
      border: 'none',
      zIndex: 0
    }
  }
)

const Sidebar: FC<Props> = () => {
  return (
    <Drawer
      open
      variant="permanent"
      data-testid={TEST_HOOKS.SIDEBAR}
    >
      <Toolbar />
      <MainMenu />
    </Drawer>
  )
}

const TEST_HOOKS = {
  SIDEBAR: 'sidebar'
}

// FIXME: Either add props or remove the interface!
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {

}

export default Sidebar

export {
  Sidebar,
  TEST_HOOKS,
  type Props
}
