import React, { FC } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import { Box, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'

import { Tournament } from 'shared/types'

import JoinModalContent from './JoinModalContent'

const JoinModal: FC<Props> = observer(({ open, tournament, onCloseClick }) => {
  return (
    <Dialog
      open={open}
      onClose={onCloseClick}
      maxWidth='xl'
      scroll='paper'
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      disableScrollLock={ true }
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '60%',
            height: '80%'
          }
        }
      }}
    >
      <DialogTitle sx={{ bgcolor: '#14213D', padding: '10px 15px' }} id="scroll-dialog-title">
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography fontWeight={600} sx={{ flexGrow: 1 }}>Select your horse</Typography>
          <CloseIcon sx={{ cursor: 'pointer' }} onClick={onCloseClick} />
        </Box>
      </DialogTitle>
      <DialogContent sx={{ bgcolor: '#14213D' }} dividers={true}>
        <Box
          id="scroll-dialog-description"
          tabIndex={-1}
          width='100%'
        >
          <JoinModalContent tournament={tournament} />
        </Box>
      </DialogContent>
    </Dialog>)
})

const TEST_HOOKS = {
  JOINMODAL: 'joinModal'
}

interface Props {
  tournament: Tournament
  open: boolean
  onCloseClick: () => void
}

export default JoinModal

export {
  JoinModal,
  TEST_HOOKS,
  type Props
}
