import React, { FC, useEffect } from 'react'

import { Box, Grid } from '@mui/material'
import { observer } from 'mobx-react-lite'

import promo1 from 'shared/images/promo1.png'
// import promo2 from 'shared/images/promo2.png'
import promo3 from 'shared/images/promo3.png'
import { Tournament } from 'shared/types'

import { DefaultLayout } from 'layouts'

import { useStores } from 'stores/hooks'

import { Aside, DashboardSlider, TextHighlight, TournamentCard } from 'components'

import { SliderItemProps } from 'components/DashboardSlider'

const items: SliderItemProps[] = [
  { image: promo1, key: 'promo1', link: 'https://www.frameit.gg/marketplace/HORSES-d677c8/mint?sp=true' },
  // { image: promo2, key: 'promo2', link: 'https://raffle.equistar.io/' },
  { image: promo3, key: 'promo3', link: 'https://www.estar.games/whitepaper.pdf' }
]

const DashboardPage: FC<Props> = observer(() => {
  const { tournamentsStore } = useStores()

  useEffect(() => {
    tournamentsStore.getTournamentsHistory()

    return () => {
      tournamentsStore.removeTournamentsHistoryListeners()
    }
  }, [tournamentsStore])

  const tournamentsHistory = tournamentsStore.tournamentsHistoryResource.data?.slice().reverse().slice(0, 4) ?? []

  return (
    <DefaultLayout
      data-testid={TEST_HOOKS.DASHBOARDPAGE}
      sidebar={<Aside />}
      main={
        <>
          <DashboardSlider items={items} />
          <Box mt={4} mb={4}>
            <TextHighlight variant="h2">
              Last Tournaments
            </TextHighlight>
          </Box>
          <Grid container spacing={2}>
            {tournamentsHistory.map((tournament: Tournament) => (
              <Grid key={tournament._id} item xs={12} sm={6} lg={4} xl={3}>
                <TournamentCard tournament={tournament} />
              </Grid>
            ))}
          </Grid>
        </>
      }
    />
  )
})

const TEST_HOOKS = {
  DASHBOARDPAGE: 'dashboardPage'
}

// FIXME: Either add props or remove the interface!
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {

}

export default DashboardPage

export {
  DashboardPage,
  TEST_HOOKS,
  type Props
}
