import React, { FC, useEffect } from 'react'
import './DashboardSlider.css'

import { Address } from '@elrondnetwork/erdjs/out'
import { Check, Lock } from '@mui/icons-material'
import { Avatar, Box, Button, Divider, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from '@mui/material'
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks'
import { observer } from 'mobx-react-lite'

import { useStores } from 'stores/hooks'

import TextHighlight from 'components/TextHighlight'

interface SliderItemProps {
  key: string
  image: string
  title?: string
  subheader?: string
  link?: string
}

const DashboardSlider: FC<Props> = observer(({ items }) => {
  const { address } = useGetAccountInfo()
  const { dashboard } = useStores()

  useEffect(() => {
    dashboard.quests.initialize(new Address(address)).then((data) => data).catch((e) => e)
  }, [dashboard, address])

  return (
    <Box sx={{ background: '#FFFFFF14', padding: '20px 30px', borderRadius: '20px' }}>
      <TextHighlight variant='h2' mb={2}>Daily Quests</TextHighlight>
      <List>
        {
          dashboard.quests.quests.map((quest) => (
            <Box key={quest.id}>
              <ListItem
                disableGutters
                sx={{
                  '.MuiListItemText-root': {
                    display: 'flex',
                    alignItems: 'center',
                    '&:after': {
                      display: 'block',
                      flexGrow: 1,
                      marginLeft: 4
                    }
                  }
                }
              }
                >
                <ListItemIcon>
                  <Avatar
                    sx={(theme) => ({ width: 32, height: 32, background: '#fff', color: theme.palette.primary.main })}
                  >
                    {
                      dashboard.quests.actualNbOfRaces >= quest.requirements.amount
                        ? (<Check style={{ height: '20px', color: 'green' }} />)
                        : (
                        <Lock style={{ height: '20px' }} />
                          )
                    }
                  </Avatar>
                </ListItemIcon>
                <ListItemText
                  primary={
                    dashboard.quests.actualNbOfRaces > quest.requirements.amount
                      ? `${quest.requirements.amount}/${quest.requirements.amount} ${quest.requirements.type}`
                      : `${dashboard.quests.actualNbOfRaces}/${quest.requirements.amount} ${quest.requirements.type}`
                  }
                  primaryTypographyProps={{ sx: { fontWeight: 500 } }}
                  />
                  <ListItemText
                  primary={
                    `x${quest.prize.quantity} ${quest.prize.type.replace('-', ' ')}`
                  }
                  primaryTypographyProps={{ sx: { fontWeight: 500 } }}
                  />
                <ListItemSecondaryAction>
                  {
                    quest.collected
                      ? (<Button disabled onClick={() => console.log('test')} sx={{
                          boxShadow: '2px 2px 10px #2B7FFC'
                        }}>
                      Collected
                    </Button>)
                      : quest.available
                      // eslint-disable-next-line @typescript-eslint/promise-function-async, @typescript-eslint/no-misused-promises
                        ? (<Button onClick={() => dashboard.quests.claim(dashboard.quests.day, quest, new Address(address)).then((data) => data).catch((e) => e)} sx={{
                            boxShadow: '2px 2px 12px #2B7FFC'
                          }}>
                          Claim
                        </Button>)
                        : (<Button disabled sx={{
                            boxShadow: '2px 2px 12px #2B7FFC'
                          }}>
                    Claim
                  </Button>)
                  }
                </ListItemSecondaryAction>
              </ListItem>
              <Divider sx={{
                my: '5px'
              }} />
            </Box>
          ))
        }
      </List>
    </Box>
  )
})

const TEST_HOOKS = {
  DASHBOARDSLIDER: 'dashboardSlider'
}

interface Props {
  items: SliderItemProps[]
}

export default DashboardSlider

export {
  DashboardSlider,
  TEST_HOOKS,
  type Props,
  type SliderItemProps
}
