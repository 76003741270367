import React, { FC } from 'react'

import { LockOpen, QuestionMarkOutlined } from '@mui/icons-material'
import { Button, CircularProgress } from '@mui/material'
import { useTrackTransactionStatus } from '@multiversx/sdk-dapp/hooks'
import { observer } from 'mobx-react-lite'

import { CurrencyIcons } from 'shared/constants'

import { useSendStableTransaction } from './useSendStableTransaction'

const StableCardButton: FC<Props> = observer(({ level, nextLevelPrice, token }) => {
  const { sendStableTransaction, transactionSessionId } = useSendStableTransaction()

  const handleUpgradeButtonClick = (): void => {
    if (nextLevelPrice === undefined) {
      return
    }
    sendStableTransaction(nextLevelPrice, token).catch((error) => {
      console.log(error)
    })
  }

  const transactionStatus = useTrackTransactionStatus({
    transactionId: transactionSessionId
  })

  const buttonDisabled = level === 5
  const Icon = CurrencyIcons.get(token) ?? QuestionMarkOutlined

  if (level === undefined) return null
  return <Button
  variant="contained"
  color="primary"
  onClick={() => { handleUpgradeButtonClick() }}
  disabled={buttonDisabled || transactionStatus.isPending}
  sx={{ fontSize: '20px', fontWeight: '600', borderRadius: '20px', mb: '7px' }}
  startIcon={(level === 0)
    ? (<LockOpen />)
    : (<Icon style={{ height: '20px' }} />)}
>
  {(transactionStatus.isPending === true)
    ? <CircularProgress size={15} color="inherit" />
    : <>
      {(level === 0)
        ? (<>Unlock <Icon style={{ height: '20px', margin: '4px 0px 0px 10px' }} /> </>)
        : (level <= 4)
            ? (`Upgrade to level ${level + 1}`)
            : ('MAX Level')}
    </>}
</Button>
})

interface Props {
  level: number | undefined
  nextLevelPrice: number | undefined
  token: 'estar' | 'egld'
}

export default StableCardButton
