import { io, Socket } from 'socket.io-client'

import { BACKEND_URL } from 'config'

import { IBackendService } from 'shared/types'

class BackendService implements IBackendService {
  backendConnectionString: string = BACKEND_URL
  socket: Socket

  constructor () {
    this.socket = io(this.backendConnectionString)
  }

  emit (event: string, ...args: any): void {
    this.socket.emit(event, ...args)
  }

  emitWithAck (event: string, ack?: (data: any) => void, ...args: any): void {
    this.socket.emit(event, ...args, ack)
  }

  disconnect (event: string): void {
    this.socket.off(event)
  }

  on (
    eventName: string,
    callback: (data: any) => void,
    errorCallback: (error: any) => void
  ): void {
    try {
      this.socket.on(eventName, callback)
    } catch (error) {
      console.error(`${eventName} event:`, error)
      errorCallback(error)
    }
  }
}
export default BackendService
