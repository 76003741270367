import React, { FC } from 'react'

import { Divider, IconButton, List, ListItem, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink, useLocation } from 'react-router-dom'

import { appRoutes } from 'config/routes'

import { Logout } from 'shared/icons/menu'
import { boxShadow } from 'shared/theme'
import { checkIfUrlIsActive } from 'shared/utils'

const MenuItem: FC<MenuItemProps> = ({ name, path, Icon, current = false }) => {
  return (
    <ListItem sx={{ paddingTop: 1.5, paddingBottom: 1.5, width: 'auto' }}>
      <Tooltip title={name} placement="right">
        <IconButton
          component={RouterLink}
          to={path}
          size="large"
          sx={(theme) => ({
            background: current ? `${theme.palette.primary.main}` : 'transparent',
            boxShadow: current ? boxShadow() : 'none',
            transition: 'background .2s, box-shadow .2s, color .2s',
            '&:hover': {
              boxShadow: 'none'
            }
          })}
        >
          <Icon />
        </IconButton>
      </Tooltip>
    </ListItem>
  )
}

interface MenuItemProps {
  name: string
  path: string
  Icon: FC
  current?: boolean
}

const Spacer: FC = () => {
  return (
    <ListItem sx={{ width: '50%' }}>
      <Divider sx={{ borderWidth: 1, width: '100%' }} />
    </ListItem>
  )
}

const MainMenu: FC<Props> = () => {
  const location = useLocation()
  const { t } = useTranslation()

  return (
    <List
      data-testid={TEST_HOOKS.MAINMENU}
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 4
      }}
    >
      {appRoutes.map(route => {
        const menuItems = [
          <MenuItem
            key={route.name}
            name={t(route.name)}
            path={route.path as string}
            Icon={route.icon}
            current={checkIfUrlIsActive(location, route.path as string)}
          />
        ]
        if (route.spacerBefore != null) menuItems.unshift(<Spacer key={`${route.name}-spacer`} />)
        return menuItems
      })}
      <Spacer key={'Logout-spacer'} />
      <MenuItem
        key={'Logout'}
        name={'Logout'}
        path={'/logout'}
        Icon={Logout}
        current={checkIfUrlIsActive(location, '/logout')}
      />
    </List>
  )
}

const TEST_HOOKS = {
  MAINMENU: 'mainMenu'
}

// FIXME: Either add props or remove the interface!
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {

}

export default MainMenu

export {
  MainMenu,
  TEST_HOOKS,
  type Props
}
