import { Address } from '@elrondnetwork/erdjs/out'
import { makeAutoObservable } from 'mobx'

import { socket } from 'config'

import { RacePreview } from 'shared/gameResources'
import { EmitEvents, ReceiveEvents } from 'shared/types'

class RacesHistoryStore {
  races: RacePreview[] = []

  constructor () {
    makeAutoObservable(this)
  }

  async initialize (address: Address): Promise<void> {
    // FIXME: Extract socket-related logic to some sort of service
    socket.emit(EmitEvents.AllRaces, address)
    socket.on(ReceiveEvents.AllRaces, (data) => {
      this.races = data
    })
  }

  reset (): void {
    socket.off(ReceiveEvents.AllRaces)
  }
}

export default RacesHistoryStore
