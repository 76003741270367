import React, { FC, useEffect, useState } from 'react'

import { useGetAccount } from '@multiversx/sdk-dapp/hooks'
import { Loader, PageState } from '@multiversx/sdk-dapp/UI'
import { observer } from 'mobx-react-lite'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'

import { tokens } from 'config'

import { Status } from 'shared/resource'

import { useStores } from 'stores/hooks'

import { TextHighlight } from 'components'

import PayRace from './PayRace'
import SelectRaceHorse from './SelectRaceHorse/index'

const EnterRaceContent: FC = observer(() => {
  const { id } = useParams()
  const raceId = id === undefined ? undefined : decodeURIComponent(id)

  const [isPaid, setIsPaid] = useState(false)

  const { racesStore } = useStores()
  const { address } = useGetAccount()

  const raceDoc = raceId === undefined ? undefined : racesStore.races.get(raceId)?.data?.race

  const [token, setToken] = useState({
    token: 'ESTAR',
    identifier: 'ESTAR-461bab',
    decimals: 100
  })

  useEffect(() => {
    // eslint-disable-next-line array-callback-return
    tokens.map((item) => {
      if (item.token === raceDoc?.with) {
        setToken(item)
      }
    })
  }, [raceDoc?.with])

  useEffect(() => {
    if (raceId === undefined) {
      return
    }

    racesStore.getRace(raceId, address)

    return () => {
      racesStore.removeGetRaceResource()
    }
  }, [racesStore, raceId, address])

  useEffect(() => {
    if (raceDoc?.entryFee === 0) {
      setIsPaid(true)
    }
  }, [raceDoc?.entryFee])

  if (raceId === undefined) {
    return <PageState
      className='text-muted fa-3x'
      title='Race id not found'
    />
  }

  if (racesStore.races.get(raceId)?.status === Status.LOADING) {
    <Loader className='loader centered' noText />
  }

  if (raceDoc == null || racesStore.races.get(raceId)?.error !== undefined) {
    return <PageState
      className='text-muted fa-3x'
      title='An error has occurred. Please try again later.'
    />
  }

  return (
    <>
      <Helmet title="Races" />
      <TextHighlight variant="h2">
        ENTER RACE {raceId}
      </TextHighlight>
      {isPaid ? <SelectRaceHorse raceDoc={raceDoc} token={token} /> : <PayRace raceDoc={raceDoc} setIsPaid={setIsPaid} token={token} />}
    </>
  )
})

const TEST_HOOKS = {
  ENTERRACECONTENT: 'enterRaceContent'
}

export default EnterRaceContent

export {
  EnterRaceContent,
  TEST_HOOKS
}
