import { Address } from '@elrondnetwork/erdjs/out'
import { makeAutoObservable } from 'mobx'

import { Store } from 'shared/types'

import RacesHistoryStore from '../RacesHistoryStore'
import RootStore from '../RootStore'

class AsideStore implements Store {
  rootStore: RootStore

  lastRaces: RacesHistoryStore

  constructor (rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
    this.lastRaces = new RacesHistoryStore()
  }

  async initialize (address: string): Promise<void> {
    void await this.lastRaces.initialize(new Address(address))
  }

  reset (): void {
    this.lastRaces.reset()
  }
}

export default AsideStore
