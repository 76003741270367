import { type Location } from 'react-router-dom'

import { EQUISTAR_IDENTIFIER, HORSES_IDENTIFIER } from '../config'

/**
 * Check if the current URL is an active one, by the location pathname.
 * Note that it will return true for sub-routes, i.e. url `/services` is active for pathname `/services/32`.
 * @param {Location}  location  Location, best provided by the `useLocation` hook from `react-router` library.
 * @param {string}    url       URL to check against, whether it's active or not.
 */
const checkIfUrlIsActive = (location: Location, url: string): boolean => {
  if (['/', '/app'].includes(url)) return location.pathname === url
  return location.pathname.startsWith(url)
}

const getNftUrl = (name: string, collectionId?: string): string => {
  if (collectionId === EQUISTAR_IDENTIFIER) {
    return 'https://media.elrond.com/nfts/asset/QmeQ1XEMuQDnQsWEMuZXhCj2ofSErKjqcWk8bbhh8bpYhL/' +
    name.slice(13) +
    '.png'
  }
  if (collectionId === HORSES_IDENTIFIER) {
    return 'https://media.elrond.com/nfts/asset/QmcPjq6vDBLiH9PWCnaaMdJi5fj8xLE4VnWwoeMbX3v3S3/' + name.slice(10) +
    '.png'
  }
  return ''
}

const isFreeHorse = (name: string | undefined): boolean => {
  return name?.slice(0, 4).toUpperCase() === 'FREE'
}

const getTournamentPrize = (position: number, prizePool: number): number => {
  switch (position) {
    case 1:
      return 0.3 * prizePool
    case 2:
      return 0.2 * prizePool
    case 3:
      return 0.14 * prizePool
    case 4:
      return 0.10 * prizePool
    case 5:
      return 0.08 * prizePool
    case 6:
      return 0.06 * prizePool
    case 7:
      return 0.06 * prizePool
    case 8:
      return 0.06 * prizePool
    default:
      return 0
  }
}

export {
  checkIfUrlIsActive,
  getNftUrl,
  isFreeHorse,
  getTournamentPrize
}
