import { Address } from '@elrondnetwork/erdjs/out'

import { numHex } from 'shared/utils/numHex'

import { sendStakeTransaction } from './sendStakeTransaction'

export const stakeNfts = async (scAddress: string, nftsSelected: number[], address: string): Promise<void> => {
  const data = 'MultiESDTNFTTransfer@' + new Address(scAddress).hex() + '@' + numHex(nftsSelected.length) + '@' + nftsSelected.map((nonce) => Buffer.from('EQUISTAR-3f393f').toString(
    'hex'
  ) + '@' + numHex(nonce) + '@' + numHex(1)).join('@') + '@' + Buffer.from('stake').toString('hex')
  await sendStakeTransaction(data, nftsSelected.length * 7_000_000, address).catch((err) => console.log(err))
}
