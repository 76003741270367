import React, { FC, useEffect } from 'react'

import { Grid } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Helmet } from 'react-helmet'

import { RacesData } from 'shared/types'

import { DefaultLayout } from 'layouts'

import { useStores } from 'stores/hooks'

import { TextHighlight, Aside, RacesCard } from 'components'

const RacesPage: FC<Props> = observer(() => {
  const { racesStore } = useStores()
  const racesResource = racesStore.racesResource

  useEffect(() => {
    racesStore.getRaces()

    return () => {
      racesStore.removeGetRacesResource()
    }
  }, [racesStore])

  const racesData = racesResource?.data

  return (
    <DefaultLayout
      main={
        <>
          <Helmet title="Races" />
          <TextHighlight variant="h2">
            All Races
          </TextHighlight>
          <Grid container mt={2}>
            {racesData?.map((races: RacesData, index) => (
              <Grid key={index} item xs={12} md={6}>
                <RacesCard
                  racesBulk={races} bulkNumber={index}
                />
              </Grid>
            ))}
          </Grid>
        </>
      }
      sidebar={<Aside />}
    />
  )
})

const TEST_HOOKS = {
  RACESPAGE: 'racesPage'
}

// FIXME: Either add props or remove the interface!
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {

}

export default RacesPage

export {
  RacesPage,
  TEST_HOOKS,
  type Props
}
