import React, { FC } from 'react'

import { Box, Container, Grid, Link, Paper, Typography } from '@mui/material'
import {
  ExtensionLoginButton,
  WebWalletLoginButton,
  LedgerLoginButton,
  WalletConnectLoginButton
} from '@multiversx/sdk-dapp/UI'
import { observer } from 'mobx-react-lite'

import { Ledger, MaiarApp, MaiarWallet } from 'shared/icons/web3'
import { linearGradient } from 'shared/theme'
import { SvgIconComponent } from 'shared/types'

import { EquistarLogo } from 'components'

import './LandingPage.css'

const LoginMethod: FC<LoginMethodProps> = observer(({ type, Icon, name }) => {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 2,
        background: linearGradient(theme.palette.primary.darker, theme.palette.primary.main),
        transition: 'background .2s',
        width: '100%',
        '&:hover': {
          background: linearGradient(theme.palette.primary.dark, theme.palette.primary.light)
        }
      })}
    >
      <Typography
        component="span"
        sx={{ marginBottom: 2, fontSize: 12, color: 'rgba(255, 255, 255, 0.8)' }}
      >
        {type}
      </Typography>
      <Icon fontSize="large" height="36px" />
      <Typography
        component="span"
        sx={{ marginTop: 2, fontWeight: 500, fontSize: 14 }}
      >
        {name}
      </Typography>
    </Box>
  )
})

interface LoginMethodProps {
  type: string
  Icon: SvgIconComponent
  name: String
}

const LandingPage: FC<Props> = observer(() => {
  const props = {
    callbackRoute: '/app',
    nativeAuth: true
  }

  return (
    <Container
      data-testid={TEST_HOOKS.LANDINGPAGE}
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Paper
        sx={(theme) => ({
          padding: 4,
          width: '100%',
          maxWidth: theme.breakpoints.values.sm
        })}
      >
        <Box sx={{ textAlign: 'center' }}>
          <EquistarLogo href="/" />
          <Typography mt={4}>
            Connect with your MultiversX wallet to access the game.
          </Typography>
        </Box>
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          alignItems="stretch"
          spacing={2}
          mt={4}
          gridAutoRows='40px'
        >
          <Grid item xs={12} md={3}>
            <LedgerLoginButton loginButtonText='Ledger' className='login-btn' {...props} >
              <LoginMethod
                type="Hardware"
                Icon={Ledger}
                name="Ledger"
              />
            </LedgerLoginButton>
          </Grid>
          <Grid item xs={12} md={3}>
            <WebWalletLoginButton loginButtonText='MultiversX Web Wallet' className='login-btn' {...props} >
              <LoginMethod
                type="Browser"
                Icon={MaiarWallet}
                name="Web Wallet"
              />
            </WebWalletLoginButton>
          </Grid>
          <Grid item xs={12} md={3}>
            <WalletConnectLoginButton loginButtonText='Login' lead='Scan the QR code using xPortal' title='xPortal Login' buttonClassName='' className='login-btn' {...props} isWalletConnectV2={true}>
              <LoginMethod
                type="Mobile"
                Icon={MaiarApp}
                name="xPortal"
              />
            </WalletConnectLoginButton>
          </Grid>
          <Grid item xs={12} md={3}>
            <ExtensionLoginButton loginButtonText='Maiar DeFi Wallet' className='login-btn' {...props} >
              <LoginMethod
                type="Browser"
                Icon={MaiarWallet}
                name="DeFi Wallet"
              />
            </ExtensionLoginButton>
          </Grid>
        </Grid>
      </Paper>
      <Typography
        variant="body2"
        mt={4}
        sx={(theme) => ({
          marginTop: 4,
          color: theme.palette.primary.dark
        })}
      >
        {process.env.REACT_APP_NAME} v{process.env.REACT_APP_VERSION}{' '}
        {new Date().getFullYear()} by{' '}
        <Link href="https://estar.games/" target="_blank" rel="noopener noreferrer">ESTAR Games</Link>
      </Typography>
    </Container>
  )
})

const TEST_HOOKS = {
  LANDINGPAGE: 'landingPage'
}

// FIXME: Either add props or remove the interface!
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {

}

export default LandingPage

export {
  LandingPage,
  TEST_HOOKS,
  type Props
}
