import axios from 'axios'

import { BACKEND_API } from 'config'

export const fetchNftsStaked = async (scAddress: string, address: string): Promise<any[]> => {
  try {
    const { data } = await axios.get(`${BACKEND_API}/stake/${scAddress}/users/${address}`)
    if (data.length === 0) return []
    return data
  } catch (err) {
    console.log(err)
    return []
  }
}
