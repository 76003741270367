import React, { FC, useEffect } from 'react'

import { Button, Card, CardContent, CardHeader, Grid, ImageList, ImageListItem } from '@mui/material'
import { observer } from 'mobx-react-lite'

import { useStores } from 'stores/hooks'

import TextHighlight from 'components/TextHighlight'

const ClaimFreeHorses: FC<Props> = observer(({ address }) => {
  const { accountStore } = useStores()

  const checkIfClaimedResource = accountStore.checkIfClaimedFreeHorsesResource?.data
  const claimFreeHorsesResource = accountStore.claimFreeHorsesResource?.data

  console.log('checkIfClaimed', claimFreeHorsesResource)

  useEffect(() => {
    void accountStore.checkIfClaimedFreeHorses(address)

    return () => {
      accountStore.resetCheckIfClaimedFreeHorsesResource()
      accountStore.resetClaimFreeHorsesResource()
    }
  }, [accountStore, address])

  if (checkIfClaimedResource?.claimed === undefined) {
    return null
  }

  if (checkIfClaimedResource?.claimed) {
    return null
  }

  if (claimFreeHorsesResource?.status === 'SUCCESS') {
    return null
  }

  return <div>
  <Card
    sx={{
      position: 'relative',
      background: '#FFFFFF14',
      minHeight: '300px'
    }}
  >
    <CardHeader
      title={'Claim your free horses'}
      titleTypographyProps={{
        component: TextHighlight,
        textTransform: 'uppercase'
      }}
      sx={{ position: 'relative', zIndex: 1, paddingTop: 4 }}
    />
     <CardContent sx={{ position: 'relative', zIndex: 1, paddingTop: 2 }}>
      <Grid
        container
        spacing={2}
        sx={{
          fontSize: 12,
          fontWeight: 700,
          textAlign: 'center',
          textTransform: 'uppercase',
          marginBottom: 2
        }}
      >
        <Grid item xs={12}>
        <ImageList cols={3} gap={1}>
        <ImageListItem>
            <img src={'https://estar.games/images/pecan.png'} alt='free#1' />
        </ImageListItem>
        <ImageListItem>
            <img src={'https://estar.games/images/ember.png'} alt='free#2' />
        </ImageListItem>
        <ImageListItem>
            <img src={'https://estar.games/images/tawny.png'} alt='free#3' />
        </ImageListItem>
      </ImageList>
        </Grid>
        <Grid item xs={12}>
        <Button
              variant="contained"
              color="primary"
              sx={{ borderRadius: 20, marginTop: '11px', padding: '16px 12px', maxHeight: '20px', display: 'flex', mx: 'auto' }}
              onClick={() => accountStore.claimFreeHorses(address)}
        >
            Claim
        </Button>
        </Grid>
      </Grid>
    </CardContent>
    </Card>
  </div>
})

interface Props {
  address: string
}

export default ClaimFreeHorses
