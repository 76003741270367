import { FC, useEffect } from 'react'

import { logout } from '@multiversx/sdk-dapp/utils'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router'

const LogoutPage: FC<Props> = observer(() => {
  const navigate = useNavigate()

  useEffect(() => {
    const handleLogout = async (): Promise<void> => {
      await logout(window.location.origin)
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    handleLogout()
  }, [navigate])

  return null
})

const TEST_HOOKS = {
  LOGOUTPAGE: 'logoutPage'
}

// FIXME: Either add props or remove the interface!
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {

}

export default LogoutPage

export {
  LogoutPage,
  TEST_HOOKS,
  type Props
}
