import { sendTransactions } from '@multiversx/sdk-dapp/services'
import { refreshAccount } from '@multiversx/sdk-dapp/utils'

import { TOKEN_IDENTIFIER, GAME_ADDRESS } from 'config'

import { numHex } from 'shared/utils/numHex'
import useLocalStorage from 'shared/utils/useLocalStorage'

export const useSendFarmTransaction = (): { sendFarmTransaction: (price: number | undefined, token: 'estar' | 'egld') => Promise<void>, transactionSessionId: string } => {
  const [transactionSessionId, setTransactionSessionId] = useLocalStorage<string | null>(
    'payTransactionFarmSessionId',
    null
  )

  const sendFarmTransaction = async (price: number | undefined, token: 'estar' | 'egld'): Promise<void> => {
    if (price === undefined) {
      return
    }

    let transaction
    if (token === 'estar') {
      transaction = {
        value: 0,
        gasLimit: '6000000',
        data:
          Buffer.from(
            'ESDTTransfer@' + Buffer.from(TOKEN_IDENTIFIER).toString('hex') + '@' + numHex(price) + '@' + Buffer.from('upgradeFarm').toString('hex')
          ),
        receiver: GAME_ADDRESS
      }
    } else {
      transaction = {
        value: price,
        gasLimit: '6000000',
        data:
          Buffer.from(
            'upgradeFarm'
          ),
        receiver: GAME_ADDRESS
      }
    }
    await refreshAccount()

    const { sessionId } = await sendTransactions({
      transactions: transaction,
      transactionsDisplayInfo: {
        processingMessage: 'Processing transaction',
        errorMessage: 'An error has occured during',
        successMessage: 'Transaction successful'
      },
      redirectAfterSign: false
    })
    if (sessionId != null) {
      setTransactionSessionId(sessionId)
    }
  }

  return { sendFarmTransaction, transactionSessionId }
}
