import React, { FC } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { observer } from 'mobx-react-lite'

const GenericModal: FC<Props> = observer(({ open, message, title, onCloseClick }) => {
  return (
    <div>
      <Dialog
        open={open}
        onClose={onCloseClick}
        maxWidth='xl'
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        disableScrollLock={true}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: 'fit-content',
              height: 'fit-content'
            }
          }
        }}
      >
        <DialogTitle sx={{ bgcolor: '#14213D', padding: '10px 15px' }} id="scroll-dialog-title">
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography> {title} </Typography>
            <CloseIcon sx={{ cursor: 'pointer' }} onClick={onCloseClick} />
          </Box>
        </DialogTitle>
        <DialogContent sx={{ bgcolor: '#14213D' }} dividers={true}>
          <Box
            id="scroll-dialog-description"
            tabIndex={-1}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              width: 'fit-content',
              height: 'fit-content'
            }}
          >
            <Typography>
              {message}
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  )
})

const TEST_HOOKS = {
  GENERICMODAL: 'genericModal'
}

interface Props {
  open: boolean
  message?: string
  title?: string
  onCloseClick: () => void
}

export default GenericModal

export {
  GenericModal,
  TEST_HOOKS,
  type Props
}
