import React, { FC, useMemo } from 'react'

import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { useGetAccount } from '@multiversx/sdk-dapp/hooks'
import { observer } from 'mobx-react-lite'

import { Player, ViewStageTournament } from 'shared/types'
import { getTournamentPrize } from 'shared/utils'

import { TournamentStage } from '../../TournamentsDetailsPage'

import Username from './Username'

const ViewStage: FC<Props> = observer(({ stage, tournament }) => {
  const { address } = useGetAccount()
  const players = useMemo(() => {
    switch (stage) {
      case TournamentStage.STAGE1:
        return tournament.stages[0].players
      case TournamentStage.STAGE2:
        return tournament.stages[1].players
      default:
        return tournament.winners
    }
  }, [stage, tournament])

  return (
    <>
      <Typography fontSize={'36px'} fontWeight={700} sx={{ color: '#FFC300', mt: 4 }}>
        {stage}
      </Typography>
      <Grid container mt={2}>
        <Grid item xs={12} md={10}>
          <TableContainer component={Paper} sx={{ background: '#FFFFFF14', paddingTop: '31px', paddingBottom: '31px' }}>
            <Table sx={{ minWidth: 700 }} aria-label="table">
              <TableHead>
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  {stage === TournamentStage.WINNERS && <TableCell sx={{ fontSize: '20px', fontWeight: 400 }} align="center">Place</TableCell>}
                  <TableCell sx={{ fontSize: '16px', fontWeight: 400 }} align="center">Username</TableCell>
                  <TableCell sx={{ fontSize: '16px', fontWeight: 400 }} align="center">Horse</TableCell>
                  <TableCell sx={{ fontSize: '16px', fontWeight: 400 }} align="center">Score</TableCell>
                  {stage === TournamentStage.WINNERS && <TableCell sx={{ fontSize: '20px', fontWeight: 400 }} align="center">Prize Won</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {players?.map((player: Player) => (
                  <TableRow key={player.position}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, color: player.address === address ? '#32AF97' : 'inherit' }}
                  >
                    {stage === TournamentStage.WINNERS && <TableCell sx={{ fontSize: '18px', fontWeight: 600, border: 0, color: 'inherit' }} align="center">{player.position + 1}</TableCell>}
                    <TableCell sx={{ fontSize: '18px', fontWeight: 600, border: 0, color: 'inherit' }} align="center"><Username player={player}/></TableCell>
                    <TableCell sx={{ fontSize: '18px', fontWeight: 600, border: 0, color: 'inherit' }} align="center">{player.horse}</TableCell>
                    <TableCell sx={{ fontSize: '18px', fontWeight: 600, border: 0, color: 'inherit' }} align="center">{player.score.toFixed(2)}</TableCell>
                    {stage === TournamentStage.WINNERS && <TableCell sx={{ fontSize: '18px', fontWeight: 600, border: 0, color: 'inherit' }} align="center">{getTournamentPrize(player.position + 1, tournament.prizePool).toFixed(2)} {tournament.prizeInToken}</TableCell>}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  )
}
)

const TEST_HOOKS = {
  VIEWSTAGE: 'viewStage'
}

interface Props {
  stage: TournamentStage
  tournament: ViewStageTournament
}

export default ViewStage

export {
  ViewStage,
  TEST_HOOKS,
  type Props
}
