import { Address } from '@elrondnetwork/erdjs/out'
import { makeAutoObservable } from 'mobx'

import { socket } from 'config'

import { EmitEvents, QuestsResponse, Quest, GenericResponse } from 'shared/types'

class QuestsStore {
  actualNbOfRaces: number = 0
  day: string = ''
  quests: Quest[] = []

  constructor () {
    makeAutoObservable(this)
  }

  async initialize (address: Address): Promise<void> {
    socket.emit(EmitEvents.GetChristmasEvent, address.bech32(), (response: QuestsResponse) => {
      if (response.status === 'FAIL' || response.data === undefined) return
      this.actualNbOfRaces = response.data.actualNbOfRaces
      this.day = response.data.day
      this.quests = response.data.quests
    })
  }

  async claim (day: string, item: Quest, address: Address): Promise<void> {
    socket.emit(EmitEvents.ClaimChristmasEvent, day, item, address.bech32(), (response: GenericResponse) => {
      if (response.status === 'SUCCESS') {
        this.quests = this.quests.map((quest) => {
          if (quest.id === item.id) {
            return {
              ...quest,
              collected: true
            }
          } else {
            return quest
          }
        })
      }
    })
  }

  reset (): void {
    // socket.off(EmitEvents.GetChristmasEvent)
  }
}

export default QuestsStore
