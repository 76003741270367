import React, { FC, ReactNode } from 'react'

import {
  AddCircleOutline,
  Instagram,
  LinkedIn,
  TokenTwoTone,
  Twitter,
  YouTube
} from '@mui/icons-material'
import { Button, Grid, IconButton, Link, Stack, Typography } from '@mui/material'
import chroma from 'chroma-js'

const SocialLink: FC<SocialLinkProps> = ({ url, icon }) => {
  return (
    <IconButton
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      sx={(theme) => ({ color: theme.palette.grey['400'] })}
    >
      {icon}
    </IconButton>
  )
}

interface SocialLinkProps {
  url: string
  icon: ReactNode
}

const Footer: FC<Props> = () => {
  return (
    <Grid
      container
      data-testid={TEST_HOOKS.FOOTER}
      mt={12}
      sx={(theme) => ({
        fontSize: '14px',
        fontWeight: 500,
        color: chroma(theme.palette.primary.darker).mix('white').hex()
      })}
    >
      <Grid item xs={12} md>
        <Typography fontSize={16} fontWeight={700} gutterBottom>
          {process.env.REACT_APP_NAME} v{process.env.REACT_APP_VERSION}
        </Typography>
        <Typography fontSize="inherit">
          &copy; + <abbr title="love" style={{ textDecoration: 'none', pointerEvents: 'none' }}>❤️</abbr>{' '}
          {new Date().getFullYear()} by{' '}
          <Link
            href="https://estar.games/"
            target="_blank"
            rel="noopener noreferrer"
          >
            ESTAR Games
          </Link>
        </Typography>
      </Grid>
      <Grid item xs={12} md="auto">
        <Stack spacing={1} direction="row">
          <Button
            variant="contained"
            size="small"
            href="https://www.frameit.gg/marketplace/HORSES-d677c8/mint?sp=true"
            target="_blank"
            rel="noopener noreferrer"
            startIcon={<AddCircleOutline />}
            sx={(theme) => ({
              boxShadow: 'none',
              backgroundColor: theme.palette.primary.dark,
              '&:hover': {
                backgroundColor: theme.palette.primary.darker
              }
            })}
          >
            Mint
          </Button>
          <SocialLink url="https://instagram.com/estargames/" icon={<Instagram />} />
          <SocialLink url="https://twitter.com/EstarToken" icon={<Twitter />} />
          <SocialLink url="https://tiktok.com/@estar.games" icon={<TokenTwoTone />} />
          <SocialLink url="https://youtube.com/channel/UCIIG8P1T9rMWjgUr-e34K3Q" icon={<YouTube />} />
          <SocialLink url="https://linkedin.com/company/estar-games/" icon={<LinkedIn />} />
        </Stack>
      </Grid>
    </Grid>
  )
}

const TEST_HOOKS = {
  FOOTER: 'footer'
}

// FIXME: Either add props or remove the interface!
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {

}

export default Footer

export {
  Footer,
  TEST_HOOKS,
  type Props
}
