import React, { FC } from 'react'

import { Grid } from '@mui/material'
import { useGetActiveTransactionsStatus, useGetAccount } from '@multiversx/sdk-dapp/hooks'

import { STAKING_SCS } from 'config'

import { fetchNfts } from '../hooks'

import { StakingCard } from './StakingCard'

const StakingCardsGrid: FC = () => {
  const { address } = useGetAccount()
  const [nfts, setNfts] = React.useState<any[]>([])
  const { success, fail } = useGetActiveTransactionsStatus()

  React.useEffect(() => {
    fetchNfts(address).then((nfts) => setNfts(nfts)).catch((err) => console.log(err))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, fail])

  return (
    <Grid container spacing={2}>
      {STAKING_SCS.map((card, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <StakingCard {...card} nfts={nfts} />
        </Grid>
      ))}
    </Grid>
  )
}

export default StakingCardsGrid
