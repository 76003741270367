import React, { FC } from 'react'

import { observer } from 'mobx-react-lite'
import { Helmet } from 'react-helmet'

import { DefaultLayout } from 'layouts'

import { Aside } from 'components'

import EnterRaceContent from './EnterRaceContent'

const EnterRacePage: FC = observer(() => {
  return (
    <DefaultLayout
      main={
        <>
          <Helmet title="Races" />
          <EnterRaceContent />
        </>
      }
      sidebar={<Aside />}
    />
  )
})

const TEST_HOOKS = {
  ENTERRACEPAGE: 'enterRacePage'
}

export default EnterRacePage

export {
  EnterRacePage,
  TEST_HOOKS
}
