import { makeAutoObservable } from 'mobx'

import { Resource } from 'shared/resource'
import { Account, BuyMarketItem, CheckResponse, ClaimableReward, CreateAccountResponse, GenericResponse, MarketItemType, StableResponse, StatusNft, Store } from 'shared/types'

import { AccountService } from '../../services/AccountService'
class AccountStore implements Store {
  accountResource?: Resource<Account>
  statusResource?: Resource<StatusNft[]>
  createOrEditAccountResource?: Resource<CreateAccountResponse>
  rewardsResource?: Resource<ClaimableReward[]>
  claimFreeHorsesResource?: Resource<GenericResponse>
  checkIfClaimedFreeHorsesResource?: Resource<CheckResponse>
  stableResource?: Resource<StableResponse>
  buyMarketItemResource?: Resource<GenericResponse>
  marketItemsResource?: Resource<MarketItemType[]>

  accountService: AccountService

  constructor (accountService: AccountService) {
    this.accountService = accountService
    makeAutoObservable(this)
  }

  getAccount (
    address: string
  ): void {
    this.accountResource = Resource.loading()
    this.accountService.getAccount(address, (data) => {
      this.accountResource = Resource.success(data)
    }, (error) => {
      this.accountResource = Resource.error(error)
    })
  }

  getMarketItems (
  ): void {
    this.marketItemsResource = Resource.loading()
    this.accountService.getMarketItems((data) => {
      this.marketItemsResource = Resource.success(data)
    }, (error) => {
      this.marketItemsResource = Resource.error(error)
    })
  }

  getStatus (
    address: string
  ): void {
    this.statusResource = Resource.loading()
    this.accountService.getStatus(address, (data) => {
      this.statusResource = Resource.success(data)
    }, (error) => {
      this.statusResource = Resource.error(error)
    })
  }

  createOrEditAccount (
    address: string,
    username: string,
    editAccount: boolean
  ): void {
    this.createOrEditAccountResource = Resource.loading()
    const successCallback = (data: string): void => {
      this.createOrEditAccountResource = Resource.success(data as CreateAccountResponse)
    }

    const errorCallback = (error: any): void => {
      this.createOrEditAccountResource = Resource.error(error)
    }

    if (editAccount) {
      this.accountService.editAccount(address, username, successCallback, errorCallback)
    } else {
      this.accountService.createAccount(address, username, successCallback, errorCallback)
    }
  }

  getRewards (
    address: string
  ): void {
    this.rewardsResource = Resource.loading()
    this.accountService.getRewards(address,
      (data: ClaimableReward[] | null) => {
        this.rewardsResource = Resource.success(data != null ? data : [])
      }, (error) => {
        this.rewardsResource = Resource.error(error)
      })
  }

  getStable (
    address: string
  ): void {
    this.stableResource = Resource.loading()
    this.accountService.getStable(address,
      (data: StableResponse) => {
        this.stableResource = Resource.success(data)
      }, (error) => {
        this.stableResource = Resource.error(error)
      })
  }

  upgradeStable (
    address: string
  ): void {
    this.accountService.upgradeStable(address,
      (response: any) => {
        this.stableResource = Resource.success(response.data)
      }, (error) => {
        this.stableResource = Resource.error(error)
      })
  }

  buyMarketItem (
    item: BuyMarketItem,
    address: string
  ): void {
    this.buyMarketItemResource = Resource.loading()
    this.accountService.buyMarketItem(item, address,
      (data: any) => {
        this.buyMarketItemResource = Resource.success(data)
      }, (error) => {
        this.buyMarketItemResource = Resource.error(error)
      })
  }

  checkIfClaimedFreeHorses (
    address: string
  ): void {
    this.checkIfClaimedFreeHorsesResource = Resource.loading()
    this.accountService.checkIfClaimedFreeHorses(address,
      (data: CheckResponse) => {
        this.checkIfClaimedFreeHorsesResource = Resource.success(data)
      }, (error) => {
        this.checkIfClaimedFreeHorsesResource = Resource.error(error)
      })
  }

  claimFreeHorses (
    address: string
  ): void {
    this.claimFreeHorsesResource = Resource.loading()
    this.accountService.claimFreeHorses(address,
      (data: GenericResponse) => {
        this.claimFreeHorsesResource = Resource.success(data)
      }, (error) => {
        this.claimFreeHorsesResource = Resource.error(error)
      })
  }

  reset (): void {
  }

  removeCreateOrEditAccountListeners (): void {
    this.accountService.removeCreateAccountListener()
    this.accountService.removeEditAccountListener()
  }

  removeGetAccountListeners (): void {
    this.accountService.removeGetAccountListener()
  }

  removeGetMarketItemsListeners (): void {
    this.accountService.removeGetMarketItemsListener()
  }

  removeGetStatusListeners (): void {
    this.accountService.removeGetStatusListener()
  }

  resetClaimFreeHorsesResource (): void {
    this.claimFreeHorsesResource = undefined
  }

  resetCheckIfClaimedFreeHorsesResource (): void {
    this.checkIfClaimedFreeHorsesResource = undefined
  }

  resetCreateOrEditAccountResource (): void {
    this.createOrEditAccountResource = undefined
  }

  resetBuyMarketItemResource (): void {
    this.buyMarketItemResource = undefined
  }
}

export default AccountStore
