import React, { FC } from 'react'

import { ChevronRightOutlined, QuestionMarkOutlined } from '@mui/icons-material'
import { Avatar, IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { CurrencyIcons } from 'shared/constants'
import { RacePreview } from 'shared/gameResources'
import { Currency } from 'shared/types'

const RaceItem: FC<RaceItemProps> = ({ _id, startDate, endDate, currency = 'ESTAR' }) => {
  const navigate = useNavigate()
  const Icon = CurrencyIcons.get(currency) ?? QuestionMarkOutlined

  const handleClickViewRace = (): void => {
    navigate(`/app/view/race/${encodeURIComponent(_id)}`)
  }

  return (
    <ListItem
      disableGutters
      sx={(theme) => ({
        '.MuiListItemText-root': {
          display: 'flex',
          alignItems: 'center',
          '&:after': {
            display: 'block',
            flexGrow: 1,
            height: '1px',
            marginLeft: 4,
            background: theme.palette.grey['800'],
            content: '""'
          }
        }
      })}
    >
      <ListItemIcon>
        <Avatar
          sx={(theme) => ({ width: 32, height: 32, background: '#fff', color: theme.palette.primary.main })}
        >
          <Icon style={{ height: '20px' }} />
        </Avatar>
      </ListItemIcon>
      <ListItemText
        primary={_id}
        secondary={startDate}
        primaryTypographyProps={{ sx: { fontWeight: 500 } }}
        secondaryTypographyProps={{ sx: { marginLeft: 4 } }}
      />
      <ListItemSecondaryAction>
        <IconButton edge="end" onClick={handleClickViewRace}>
          <ChevronRightOutlined />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  )
}

interface RaceItemProps extends RacePreview {
  currency?: Currency
}

const LastRacesList: FC<Props> = ({ races }) => {
  return (
    <List data-testid={TEST_HOOKS.LASTRACESLIST}>
      {races.map((race) => (
        <RaceItem key={race._id} _id={race._id} startDate={race.startDate} endDate={race.endDate} />
      ))}
    </List>
  )
}

const TEST_HOOKS = {
  LASTRACESLIST: 'lastRacesList'
}

interface Props {
  races: RaceItemProps[]
}

export default LastRacesList

export {
  LastRacesList,
  TEST_HOOKS,
  type Props
}
