import { makeAutoObservable } from 'mobx'
import { TournamentsService } from 'services'

import { Resource } from 'shared/resource'
import { CreateTournament, Store } from 'shared/types'
class AdminStore implements Store {
  createTournamentResource?: Resource<any>
  isAdminResource?: Resource< boolean >
  tournamentsService: TournamentsService

  constructor (tournamentsService: TournamentsService) {
    this.tournamentsService = tournamentsService
    makeAutoObservable(this)
  }

  createTournament (
    createInput: CreateTournament,
    address: string
  ): void {
    this.createTournamentResource = Resource.loading()
    this.tournamentsService.createTournament(createInput, address,
      (data: any) => {
        this.createTournamentResource = Resource.success(data)
      }, (error) => {
        this.createTournamentResource = Resource.error(error)
      })
  }

  isAdmin (
    address: string
  ): void {
    this.isAdminResource = Resource.loading()
    this.tournamentsService.isAdmin(address,
      (data: any) => {
        this.isAdminResource = Resource.success(data)
      }, (error) => {
        this.isAdminResource = Resource.error(error)
      })
  }

  reset (): void {
    this.tournamentsService.removeCreateTournamentListener()
    this.createTournamentResource = undefined
  }

  removeIsAdminListeners (): void {
    this.tournamentsService.removeIsAdminListener()
  }
}

export default AdminStore
