import React, { FC, useEffect, useState } from 'react'

import { QuestionMarkOutlined } from '@mui/icons-material'
import { Box, Button, Tooltip, Typography } from '@mui/material'
import { useGetAccount } from '@multiversx/sdk-dapp/hooks'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'

import { tokens } from 'config'

import { CurrencyIcons } from 'shared/constants'
import { RaceDoc, ResponseStatus } from 'shared/types'

import { useStores } from 'stores/hooks'

import GenericModal from '../../../components/GenericModal/GenericModal'

const PayRaceButton: FC<Props> = observer(({ raceDoc, type }) => {
  const navigate = useNavigate()
  const { racesStore } = useStores()
  const { address } = useGetAccount()
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [title, setTitle] = useState('')

  const handleClose = (): void => setOpen(false)

  const handleClick = (): void => {
    racesStore.getAvailable(raceDoc, token, address)
  }
  useEffect(() => {
    const answer = racesStore.getAvailableResource?.data
    if (answer?.status === ResponseStatus.FAIL) {
      setOpen(true)
      setMessage(answer.message)
      setTitle(answer.status)
      racesStore.resetGetAvailableResource()
      return
    }
    if (answer?.status === ResponseStatus.SUCCESS) {
      navigate(`/app/enter/race/${encodeURIComponent(answer.race._id)}`)
      racesStore.resetGetAvailableResource()
      return
    }
    if (answer?.race.entryFee === 0) {
      navigate(`/app/enter/race/${encodeURIComponent(answer.race._id)}`)
      racesStore.resetGetAvailableResource()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [racesStore.getAvailableResource?.data])

  const Icon = CurrencyIcons.get(type) ?? QuestionMarkOutlined
  const [token, setToken] = useState({
    token: 'ESTAR',
    identifier: 'ESTAR-461bab',
    decimals: 100
  })

  useEffect(() => {
    // eslint-disable-next-line array-callback-return
    tokens.map((item) => {
      if (item.token === raceDoc.with) {
        setToken(item)
      }
    })
  }, [raceDoc.with])

  return (
    <>
      <Tooltip title="Join" placement="top-end">
        <Button
          variant="contained"
          color="primary"
          onClick={handleClick}
          sx={{
            minWidth: '90px',
            borderRadius: '20px'
          }}
        >
          <Box display='flex' alignItems='center'>
            {(raceDoc.entryFee === 0)
              ? <Typography sx={{ marginRight: 2 }}>{raceDoc.stamina}</Typography>
              : <Typography sx={{ marginRight: 2 }}>{raceDoc.entryFee}</Typography>}
            <Icon style={{ height: '20px' }} />
          </Box>
        </Button>
      </Tooltip>
      <GenericModal open={open} onCloseClick={handleClose} message={message} title={title}/>
    </>

  )
})

const TEST_HOOKS = {
  PAYRACEBUTTON: 'payRaceButton'
}

interface Props {
  raceDoc: RaceDoc
  type: string
}

export default PayRaceButton

export { PayRaceButton, TEST_HOOKS }
