import { Tournament, TournamentDetails, ViewStageTournament } from 'shared/types'

export const tournamentToViewStage = (tournament: Tournament | TournamentDetails | undefined): ViewStageTournament => {
  let numberOfPlayers = 0

  if (tournament !== undefined && 'players' in tournament) {
    numberOfPlayers = tournament.players.length
  } else {
    numberOfPlayers = tournament?.player ?? 0
  }
  const viewStageTournament: ViewStageTournament = {
    maxPlayers: tournament?.maxPlayers ?? 0,
    players: numberOfPlayers,
    prizeInToken: tournament?.prizeInToken ?? '',
    prizePool: tournament?.prizePool ?? 0,
    stages: tournament?.stages ?? [],
    winners: tournament?.winners ?? [],
    _id: tournament?._id ?? ''
  }
  return viewStageTournament
}
