import React, { FC, useEffect } from 'react'

import { Button, Grid, Typography } from '@mui/material'
import { useGetAccount } from '@multiversx/sdk-dapp/hooks'
// import { sendTransactions } from '@multiversx/sdk-dapp/services'
// import { refreshAccount } from '@multiversx/sdk-dapp/utils'
import { observer } from 'mobx-react-lite'

// import { REWARDS_CONTRACT_ADDRESS } from 'config'

// import { ClaimableReward } from 'shared/types'
// import { strHex } from 'shared/utils/strHex'

import { useStores } from 'stores/hooks'

const ClaimRewards: FC = observer(() => {
  const { accountStore } = useStores()
  const { address } = useGetAccount()

  // const [transactionSessionId, setTransactionSessionId] = useState('')
  // const [tokenToUpdate, setTokenToUpdate] = useState<ClaimableReward>()

  const claimableRewards = accountStore.rewardsResource?.data ?? []

  useEffect(() => {
    accountStore.getRewards(address)
  }, [address, accountStore])

  // const transactionStatus = useTrackTransactionStatus({
  //   transactionId: transactionSessionId
  // })

  // useEffect(() => {
  //   if (transactionStatus.isSuccessful === undefined || tokenToUpdate === undefined) {
  //     return
  //   }

  //   accountStore.removeReward(address, tokenToUpdate)
  //   setTokenToUpdate(undefined)
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [transactionStatus, tokenToUpdate])

  // const sendTransaction = async (reward: ClaimableReward): Promise<void> => {
  //   setTokenToUpdate(reward)
  //   const data = Buffer.from('claim@' + strHex(reward.identifier))
  //   const transaction = {
  //     value: 0,
  //     gasLimit: '6000000',
  //     data: data.toString('base64'),
  //     receiver: REWARDS_CONTRACT_ADDRESS
  //   }
  //   await refreshAccount()

  //   const { sessionId } = await sendTransactions({
  //     transactions: transaction,
  //     transactionsDisplayInfo: {
  //       processingMessage: 'Processing claim transaction',
  //       errorMessage: 'An error has occured during claim',
  //       successMessage: 'Transaction successful'
  //     },
  //     redirectAfterSign: false
  //   })
  //   if (sessionId != null) {
  //     setTransactionSessionId(sessionId)
  //   }
  // }

  // const handleClaim = (reward: ClaimableReward): void => {
  //   sendTransaction(reward).catch(error => {
  //     console.log(error)
  //   })
  // }

  if (claimableRewards.length === 0) {
    return null
  }

  return (
    <>
      <Button
        className='profile-gray-btn'
        size="medium"
        variant="contained"
        disableRipple
        disableFocusRipple
        sx={{
          background: '#FFFFFF14',
          boxShadow: 'none',
          '&:hover': {
            backgroundColor: '#FFFFFF14',
            cursor: 'default'
          },
          mt: 10
        }}
      >
        <Typography fontSize={'25px'} fontWeight={600}>
          Rewards
        </Typography>
      </Button>
      {claimableRewards.map((reward) =>
        <Grid
          container
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: 2,
            width: '100%',
            marginLeft: 0
          }}
          key={reward.identifier}>
          <Typography fontSize={18} fontWeight={400}>
            ${reward.token}
          </Typography>
          <Typography fontSize={18} fontWeight={400}>
            {reward.token === 'EGLD'
              ? reward.quantity.toFixed(4)
              : reward.quantity}
          </Typography>
          {/* <Button className="joinBtn"
            variant="contained"
            color="primary"
            sx={{
              fontSize: 16,
              fontWeight: 700,
              borderRadius: 20,
              maxHeight: '39px'
            }}
            onClick={() => handleClaim(reward)}
          >
            Claim
          </Button> */}
        </Grid>
      )}
    </>
  )
})

export default ClaimRewards
