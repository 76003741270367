import React, { FC } from 'react'

import { InfoOutlined } from '@mui/icons-material'
import {
  Box,
  Card,
  CardActions,
  CardContent,
  IconButton, Tooltip,
  Typography
} from '@mui/material'
import { observer } from 'mobx-react-lite'

import { Stable } from 'shared/images/buildings'
import { Resource } from 'shared/resource'
import { StableResponse } from 'shared/types'

import lockIcon from '../../../shared/icons/lockIcon.svg'
import { getPrice } from '../hooks/getPrice'

import StableCardButton from './StableCardButton'

const StableCard: FC<Props> = observer(({ stableResource }) => {
  return (
    <Card sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', minWidth: '340px', minHeight: '360px' }}>
      <Box mt={2} sx={{ display: 'flex', flexDirection: 'row', alignContent: 'center', justifyContent: 'center' }}>
        <Typography fontSize={'24px'}>
          Stable - level {stableResource?.data?.level}
        </Typography>
        <Tooltip
          title={
            <Typography component="ul" sx={{ margin: 0, padding: 0, listStyle: 'none' }}>
              <li>Level 1 - 110 Maximum Stamina</li>
              <li>Level 2 - 130 Maximum Stamina</li>
              <li>Level 3 - 150 Maximum Stamina</li>
              <li>Level 4 - 200 Maximum Stamina</li>
              <li>Level 5 - 250 Maximum Stamina</li>
            </Typography>
          }
        >
          <IconButton color="secondary">
            <InfoOutlined />
          </IconButton>
        </Tooltip>
      </Box>
      <CardContent sx={{ textAlign: 'center', paddingBottom: '0px' }}>
        <Box position="relative">
          {(stableResource?.data?.level === 0)
            ? <>
              <img src={lockIcon}
                height={'80px'}
                style={{
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  margin: 'auto',
                  zIndex: 999
                }} alt="Your SVG" />
              <Stable style={{ minHeight: '200px', opacity: '30%', zIndex: 900 }} /></>
            : <Stable style={{ minHeight: '200px' }} />}
        </Box>
      </CardContent>
      <CardActions sx={{ display: 'flex', flexDirection: 'column' }} disableSpacing={true}>
        <StableCardButton
          level={stableResource?.data?.level}
          nextLevelPrice={stableResource?.data?.next_level_price}
          token={'estar'}
          />
        <StableCardButton
          level={stableResource?.data?.level}
          nextLevelPrice={stableResource?.data?.next_level_price_in_egld}
          token={'egld'}
          />
        <Typography mt={1}>Level Price: {getPrice(stableResource?.data?.next_level_price).toFixed(0)} ESTAR</Typography>
        <Typography mb={1}>Level Price: {getPrice(stableResource?.data?.next_level_price_in_egld)} EGLD</Typography>
      </CardActions>
    </Card>
  )
})

const TEST_HOOKS = {
  STABLECARD: 'stableCard'
}

// FIXME: Either add props or remove the interface!
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {
  stableResource: Resource<StableResponse> | undefined
}

export default StableCard

export {
  StableCard,
  TEST_HOOKS,
  type Props
}
