import React, { FC } from 'react'

import { Card, CardContent, CardHeader, Grid } from '@mui/material'

import { TextHighlight } from 'components'

import { StakingCardFunc } from './components'

const StakingCard: FC<Props> = ({
  rarity,
  rewardPerNft,
  scAddress,
  nfts
}) => {
  return (
    <div className="container">
      <Card
        data-testid={TEST_HOOKS.RACESCARD}
        sx={{
          position: 'relative',
          background: '#FFFFFF14',
          minHeight: '500px'
        }}
      >
        <CardHeader
          title={`${rarity} POOL`}
          subheader={
            rewardPerNft != null ? `${rewardPerNft} $ESTAR PER HORSE` : null
          }
          titleTypographyProps={{
            component: TextHighlight,
            textTransform: 'uppercase'
          }}
          sx={{ position: 'relative', zIndex: 1, paddingTop: 4 }}
        />
         <CardContent sx={{ position: 'relative', zIndex: 1, paddingTop: 2 }}>
          <Grid
            container
            spacing={2}
            sx={{
              fontSize: 12,
              fontWeight: 700,
              textAlign: 'center',
              textTransform: 'uppercase',
              marginBottom: 2
            }}
          >
          {rarity === 'General'
            ? (
              <Grid item xs={12}>
                <div style={{ fontSize: '20px', marginBottom: '20px', marginTop: '50px' }}>Common: 0 NFTS</div>
                <div style={{ fontSize: '20px', marginBottom: '20px' }}>Rare: 0 NFTS</div>
                <div style={{ fontSize: '20px', marginBottom: '20px' }}>Ultra Rare: 0 NFTS</div>
                <div style={{ fontSize: '20px', marginBottom: '20px' }}>Epic: 0 NFTS</div>
                <div style={{ fontSize: '20px', marginBottom: '20px' }}>Legendary: 0 NFTS</div>
              </Grid>
              )
            : (
              <Grid item xs={12}>
                <StakingCardFunc scAddress={scAddress} nfts={
                  rarity === 'Common'
                    ? [
                        ...nfts.filter((nft) => nft.metadata.attributes[0].value === rarity),
                        ...nfts.filter((nft) => nft.metadata.attributes[0].value === 'Common Gold')
                      ]
                    : nfts.filter((nft) => nft.metadata.attributes[0].value === rarity)
                } />
              </Grid>
              )
          }

          </Grid>
        </CardContent>
      </Card>
    </div>
  )
}

interface Props {
  rarity: string
  rewardPerNft?: number
  scAddress: string
  nfts: any[]
}

const TEST_HOOKS = {
  RACESCARD: 'racesCard'
}

export default StakingCard

export {
  StakingCard,
  TEST_HOOKS
}
