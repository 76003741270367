import React, { FC } from 'react'

import { LockOpen, QuestionMarkOutlined } from '@mui/icons-material'
import { Button, CircularProgress } from '@mui/material'
import { useTrackTransactionStatus } from '@multiversx/sdk-dapp/hooks'
import { observer } from 'mobx-react-lite'

import { CurrencyIcons } from 'shared/constants'

import { useSendFarmTransaction } from './useSendFarmTransaction'

const FarmCardButton: FC<Props> = observer(({ level, price, token }) => {
  const { sendFarmTransaction, transactionSessionId } = useSendFarmTransaction()
  const transactionStatus = useTrackTransactionStatus({
    transactionId: transactionSessionId
  })

  const Icon = CurrencyIcons.get(token) ?? QuestionMarkOutlined

  if (level === undefined) return null

  return <Button
  variant="contained"
  color="primary"
  onClick={() => {
    sendFarmTransaction(price, token).catch((error) => {
      console.log(error)
    })
  }}
  disabled={level === 5 || transactionStatus.isPending}
  sx={{ fontSize: '20px', fontWeight: '600', borderRadius: '20px', mb: '7px' }}
  startIcon={(level === 0)
    ? (<LockOpen />)
    : (<Icon style={{ height: '20px' }} />)}
>
  {(transactionStatus.isPending === true)
    ? <CircularProgress size={15} color="inherit" />
    : <>
      {(level === 0)
        ? (<>Unlock <Icon style={{ height: '20px', margin: '4px 0px 0px 10px' }} /> </>)
        : (level <= 4)
            ? (`Upgrade to level ${level + 1}`)
            : ('MAX Level')}
    </>}
</Button>
})

interface Props {
  level: number | undefined
  price: number | undefined
  token: 'estar' | 'egld'
}

export default FarmCardButton
