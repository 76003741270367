import { makeAutoObservable } from 'mobx'
import { TournamentsService } from 'services'

import { Resource } from 'shared/resource'
import { NftDetails, Store } from 'shared/types'

class NftsStore implements Store {
  nftsResource?: Resource<NftDetails[]>

  tournamentsService: TournamentsService

  constructor (tournamentsService: TournamentsService) {
    this.tournamentsService = tournamentsService
    makeAutoObservable(this)
  }

  getNfts (address: string): any {
    this.nftsResource = Resource.loading()
    this.tournamentsService.getNfts(address, (data) => {
      this.nftsResource = Resource.success(data.nfts)
    }, (error) => {
      this.nftsResource = Resource.error(error)
    })
  }

  reset (): void {}
}

export default NftsStore
